import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default function(){
    let chtCashUses, chtCashSources, chtCashFlows, chtCashSources_Line, chtCashUses_Line;
    let cash_sources = {}, cash_uses = {}, net_cash_flow = {};

    function toTitleCase(str) {
        str = str.replaceAll("_", " ").replace(
            /\w\S*/g,
            text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
        if (str === "Llc Distributions") {
            return "LLC Distributions";
        }
        if (str === "Payouts") {
            return "AR Payouts";
        }
        return str;
    }

    function initPagination() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        const $ulYears = $('#ulYears');

        const current_year = function(){
            return +$('li.active', $ulYears).data('fyear');
        };

        // pagination
        $('li > a', $ulYears).click(function () {
            let year_clicked = $(this).parent().data('fyear');
            if (year_clicked === 'prev') {
                year_clicked = current_year() - 1;
            } else if (year_clicked === 'next') {
                year_clicked = current_year() + 1;
            }

            if (year_clicked < init_year || year_clicked > this_year) {
                return;
            }
            if (year_clicked === current_year()) {
                return;
            }

            $('li', $ulYears).removeClass('active');
            $('li[data-fyear=' + year_clicked + ']', $ulYears).addClass('active');
            loadSourcesAndUsesPieCharts(year_clicked)
        });
    }

    function initCharts() {
        chtCashSources = new Chart(document.getElementById('chtCashSources'), {
            type: 'pie',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: context => ' ' + formatMoney(context.parsed)
                        }
                    },
                    title: {
                        display: true,
                        text: 'Cash Sources'
                    }
                }
            }
        });
        chtCashUses = new Chart(document.getElementById('chtCashUses'), {
            type: 'pie',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: context => ' ' + formatMoney(context.parsed)
                        }
                    },
                    title: {
                        display: true,
                        text: 'Cash Uses'
                    }
                }
            }
        });
        chtCashFlows = new Chart(document.getElementById('chtCashFlows'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Net Cash Flow',
                    data: [],
                    borderDash: [10,5]
                }, {
                    label: 'Cash Sources',
                    data: [],
                }, {
                    label: 'Cash Uses',
                    data: [],
                }],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
        chtCashSources_Line = new Chart(document.getElementById('chtCashSources_Line'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    title: {
                        display: true,
                        text: 'Cash Sources by Type per Year'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        itemSort: (a, b) => b.raw - a.raw,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
        chtCashUses_Line = new Chart(document.getElementById('chtCashUses_Line'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    title: {
                        display: true,
                        text: 'Cash Uses by Type per Year'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        itemSort: (a, b) => b.raw - a.raw,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
    }

    function loadSourcesAndUsesPieCharts(fyear) {
        // update chart
        let sources = {...cash_sources[fyear]}
        delete sources.total;
        sources = Object.entries(sources)
            // .filter(t => t[1] > 0)
            // .sort(function(a, b) {
            //     return b[1] - a[1];
            // })
        chtCashSources.data.labels = sources.map(t => toTitleCase(t[0]))
        chtCashSources.data.datasets = [{ data: sources.map(t => t[1]) }];
        chtCashSources.update('none');

        let uses = {...cash_uses[fyear]}
        delete uses.total;
        uses = Object.entries(uses)
            // .filter(t => t[1] > 0)
            // .sort(function(a, b) {
            //     return b[1] - a[1];
            // })
        chtCashUses.data.labels = uses.map(t => toTitleCase(t[0]))
        chtCashUses.data.datasets = [{ data: uses.map(t => t[1]) }];
        chtCashUses.update('none');
    }

    function loadSourcesAndUsesLineCharts() {
        chtCashSources_Line.data.labels = Object.keys(cash_sources)
        chtCashUses_Line.data.labels = Object.keys(cash_sources)

        const sources = Object.values(cash_sources)
        if (sources.length > 0) {
            for (let src of sources) {
                delete src.total
                src = Object.entries(src)

                src.forEach((entry, i) => {
                    if (typeof chtCashSources_Line.data.datasets[i] === "undefined") {
                        chtCashSources_Line.data.datasets[i] = {
                            label: "",
                            data: []
                        }
                    }
                    chtCashSources_Line.data.datasets[i].label = toTitleCase(entry[0])
                    chtCashSources_Line.data.datasets[i].data.push(entry[1] === 0 ? null : entry[1])
                })
            }
        }

        const uses = Object.values(cash_uses)
        if (uses.length > 0) {
            for (let use of uses) {
                delete use.total
                use = Object.entries(use)

                use.forEach((entry, i) => {
                    if (typeof chtCashUses_Line.data.datasets[i] === "undefined") {
                        chtCashUses_Line.data.datasets[i] = {
                            label: "",
                            data: []
                        }
                    }
                    chtCashUses_Line.data.datasets[i].label = toTitleCase(entry[0])
                    chtCashUses_Line.data.datasets[i].data.push(entry[1] === 0 ? null : entry[1])
                })
            }
        }

        chtCashSources_Line.update();
        chtCashUses_Line.update();
    }

    function loadCashFlowData() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        let promises = [];

        for (let y = init_year; y <= this_year; y++) {
            promises.push(
                axios.get('/api/v1/balance-sheet/?' + $.param({
                    section: 'cash_flows',
                    fyear: y,
                }))
            )
        }

        return Promise.all(promises)
            .then(responses => {

                for (const response of responses) {
                    let cash_flows = response.data.data.cash_flows;
                    let fyear = response.data.data.fyear;

                    // add to main line chart
                    cash_sources[fyear] = {...cash_flows.sources};
                    cash_uses[fyear] = {...cash_flows.uses};
                    net_cash_flow[fyear] = cash_flows.sources.total - cash_flows.uses.total;
                }

                // update line chart
                chtCashFlows.data.labels = Object.keys(net_cash_flow)
                chtCashFlows.data.datasets[0].data = Object.values(net_cash_flow)
                chtCashFlows.data.datasets[1].data = Object.values(cash_sources).map(v => v.total)
                chtCashFlows.data.datasets[2].data = Object.values(cash_uses).map(v => v.total)
                chtCashFlows.update();

                loadSourcesAndUsesPieCharts(this_year)
                loadSourcesAndUsesLineCharts()
            })
    }

    initPagination()
    initCharts()

    // start
    const $mdl = $('#mdlLoading');
    $mdl.modal({
        show: true,
        backdrop: 'static',
    })

    let message_count = 0;
    let messages = JRSPay.loading_messages();
    messages.shuffle();

    const set_new_title = function() {
        $('.modal-title', $mdl)
            .hide()
            .text(messages[message_count] + '...')
            .fadeIn(400)
        message_count++
    }

    set_new_title();
    let modal_timer = setInterval(set_new_title, 4000)

    loadCashFlowData().then(() => {
        modal_timer = undefined
        $mdl.modal('hide');
    })
}

