import $ from "jquery";

/**
 *  Layout
 * ======
 * Fixes the layout height in case min-height fails.
 * Converts the sidebar into a multilevel
 * tree view menu.
 *
 * @type Object
 * @usage self.layout.activate()
 *        self.layout.fix()
 *        self.layout.fixSidebar()
 */
class AdminLTELayout {

    constructor(sidebarSlimScroll, menu, animationSpeed) {
        this.sidebarSlimScroll = sidebarSlimScroll
        this.menu = menu;
        this.animationSpeed = animationSpeed;

        let self = this;

        $(document).off('click', menu + ' li a')
            .on('click', menu + ' li a', function (e) {
                //Get the clicked link and the next element
                const $this = $(this);
                const checkElement = $this.next();

                //Check if the next element is a menu and is visible
                if ((checkElement.is('.treeview-menu')) && (checkElement.is(':visible')) && (!$('body').hasClass('sidebar-collapse'))) {
                    //Close the menu
                    checkElement.slideUp(animationSpeed, function () {
                        checkElement.removeClass('menu-open');
                        //Fix the layout in case the sidebar stretches over the height of the window
                        //self.layout.fix();
                    });
                    checkElement.parent("li").removeClass("active");
                }
                //If the menu is not visible
                else if ((checkElement.is('.treeview-menu')) && (!checkElement.is(':visible'))) {
                    //Get the parent menu
                    let parent = $this.parents('ul').first();
                    //Close all open menus within the parent
                    let ul = parent.find('ul:visible').slideUp(animationSpeed);
                    //Remove the menu-open class from the parent
                    ul.removeClass('menu-open');
                    //Get the parent li
                    let parent_li = $this.parent("li");

                    //Open the target menu and add the menu-open class
                    checkElement.slideDown(animationSpeed, function () {
                        //Add the class active to the parent li
                        checkElement.addClass('menu-open');
                        // parent.find('li.active').removeClass('active');
                        parent_li.addClass('active');
                        //Fix the layout in case the sidebar stretches over the height of the window
                        self.fix();
                    });
                }
                //if this isn't a link, prevent the page from being redirected
                if (checkElement.is('.treeview-menu')) {
                    e.preventDefault();
                }
            });
    }

    activate() {
        let _this = this;
        _this.fix();
        _this.fixSidebar();
        $('body, html, .wrapper').css('height', 'auto');
        $(window, ".wrapper").resize(function() {
            _this.fix();
            _this.fixSidebar();
        });
    }

    fix() {
        //Get window height and the wrapper height
        let footer_height = $('.main-footer').outerHeight() || 0;
        let window_height = $(window).height();
        let sidebar_height = $(".sidebar").height() || 0;
        // Set the min-height of the content and sidebar based on the
        // the height of the document.
        if ($("body").hasClass("fixed")) {
            $(".content-wrapper, .right-side").css('min-height', window_height - footer_height);
        }
    }

    fixSidebar() {
        //Make sure the body tag has the .fixed class
        if (!$("body").hasClass("fixed")) {
            if (typeof $.fn.slimScroll != 'undefined') {
                $(".sidebar").slimScroll({destroy: true}).height("auto");
            }
            return;
        } else if (typeof $.fn.slimScroll == 'undefined' && window.console) {
            window.console.error("Error: the fixed layout requires the slimscroll plugin!");
        }
        //Enable slimscroll for fixed layout
        if (this.sidebarSlimScroll) {
            if (typeof $.fn.slimScroll != 'undefined') {
                //Destroy if it exists
                $(".sidebar").slimScroll({destroy: true}).height("auto");
                // Add slimscroll
                $(".sidebar").slimScroll({
                    height: ($(window).height() - $(".main-header").height()) + "px",
                    color: "rgba(0,0,0,0.2)",
                    size: "3px"
                });
            }
        }
    }
}

export default AdminLTELayout;
