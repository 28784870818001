import $ from "jquery";

/**
 * PushMenu()
 * ==========
 * Adds the push menu functionality to the sidebar.
 *
 * @type Function
 * @usage: self.pushMenu("[data-toggle='offcanvas']")
 */
class AdminLTEPushMenu {
    constructor(sidebarExpandOnHover, screenSizes) {
        this.sidebarExpandOnHover = sidebarExpandOnHover
        this.screenSizes = screenSizes
    }

    activate(toggleBtn) {
        let self = this;

        //Enable sidebar toggle
        $(document).on('click', toggleBtn, function(e) {
            e.preventDefault();

            //Enable sidebar push menu
            if ($(window).width() > (self.screenSizes.sm - 1)) {
                if ($("body").hasClass('sidebar-collapse')) {
                    $("body").removeClass('sidebar-collapse').trigger('expanded.pushMenu');
                } else {
                    $("body").addClass('sidebar-collapse').trigger('collapsed.pushMenu');
                }
            }
            //Handle sidebar push menu for small screens
            else {
                if ($("body").hasClass('sidebar-open')) {
                    $("body").removeClass('sidebar-open').removeClass('sidebar-collapse').trigger('collapsed.pushMenu');
                } else {
                    $("body").addClass('sidebar-open').trigger('expanded.pushMenu');
                }
            }
        });

        $(".content-wrapper").click(function() {
            //Enable hide menu when clicking on the content-wrapper on small screens
            if ($(window).width() <= (self.screenSizes.sm - 1) && $("body").hasClass("sidebar-open")) {
                $("body").removeClass('sidebar-open');
            }
        });

        //Enable expand on hover for sidebar mini
        if (self.sidebarExpandOnHover
            || ($('body').hasClass('fixed')
                && $('body').hasClass('sidebar-mini'))) {
            this.expandOnHover();
        }
    }

    expandOnHover() {
        let self = this;
        const screenWidth = self.screenSizes.sm - 1;
        //Expand sidebar on hover
        $('.main-sidebar').hover(function() {
            if ($('body').hasClass('sidebar-mini')
                && $("body").hasClass('sidebar-collapse')
                && $(window).width() > screenWidth) {
                self.expand();
            }
        }, function() {
            if ($('body').hasClass('sidebar-mini')
                && $('body').hasClass('sidebar-expanded-on-hover')
                && $(window).width() > screenWidth) {
                self.collapse();
            }
        });
    }

    expand() {
        $("body").removeClass('sidebar-collapse').addClass('sidebar-expanded-on-hover');
    }

    collapse() {
        if ($('body').hasClass('sidebar-expanded-on-hover')) {
            $('body').removeClass('sidebar-expanded-on-hover').addClass('sidebar-collapse');
        }
    }
}

export default AdminLTEPushMenu;
