import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default function(){
    let chtBillsLine, chtBillsPie;
    let bills_data = {};
    let bill_types = [];

    function initPagination() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        const $ulYears = $('#ulYears');

        const current_year = function(){
            return +$('li.active', $ulYears).data('fyear');
        };

        // pagination
        $('li > a', $ulYears).click(function () {
            let year_clicked = $(this).parent().data('fyear');
            if (year_clicked === 'prev') {
                year_clicked = current_year() - 1;
            } else if (year_clicked === 'next') {
                year_clicked = current_year() + 1;
            }

            if (year_clicked < init_year || year_clicked > this_year) {
                return;
            }
            if (year_clicked === current_year()) {
                return;
            }

            $('li', $ulYears).removeClass('active');
            $('li[data-fyear=' + year_clicked + ']', $ulYears).addClass('active');
            loadPieCharts(year_clicked)
        });
    }

    function initCharts() {
        chtBillsPie = new Chart(document.getElementById('chtBillsPie'), {
            type: 'pie',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: context => ' ' + formatMoney(context.parsed),
                        },
                    },
                    title: {
                        display: true,
                        text: 'Cash Sources'
                    }
                }
            }
        });
        chtBillsLine = new Chart(document.getElementById('chtBillsLine'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        // type: 'logarithmic',
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        itemSort: (a, b) => b.raw - a.raw,
                        callbacks: {
                            label: function(context) {
                                console.log(context)
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
    }

    function loadPieCharts(fyear) {
        chtBillsPie.data.labels = bill_types.map(t => t.name)
        chtBillsPie.data.datasets = [{ data: bill_types.map(t => +bills_data[fyear][t.name] || null) }];
        chtBillsPie.update('none');
    }

    function loadLineCharts() {
        chtBillsLine.data.labels = Object.keys(bills_data)
        chtBillsLine.data.datasets = bill_types.map(t => {
            return {
                label: t.name,
                data: []
            }
        });

        const bills = Object.values(bills_data)
        if (bills.length > 0) {
            for (let b of bills) {
                for (let bt of bill_types) {
                    let dataset = chtBillsLine.data.datasets.find(d => d.label === bt.name)
                    if (dataset) {
                        dataset.data.push(!b[bt.name] ? null : +b[bt.name])
                    }
                }
            }
        }

        chtBillsLine.update();
    }

    function loadBillsData() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        let promises = [];

        promises.push(
            axios.get('/api/v1/expenses/bill-type/list.php')
                .then(response => {
                    bill_types = response.data.data;
                })
        )

        for (let y = init_year; y <= this_year; y++) {
            promises.push(
                axios.get('/api/v1/expenses/bill/performance-by-type.php?' + $.param({
                    fyear: y,
                }))
            )
        }

        return Promise.all(promises)
            .then(responses => {

                for (const response of responses) {
                    if (!response) {
                        continue
                    }
                    let data = response.data.data.data;
                    let fyear = +response.data.data.fyear;

                    // add to main line chart
                    bills_data[fyear] = data;
                }

                loadPieCharts(this_year)
                loadLineCharts()
            })
    }

    initPagination()
    initCharts()

    // start
    const $mdl = $('#mdlLoading');
    $mdl.modal({
        show: true,
        backdrop: 'static',
    })

    let message_count = 0;
    let messages = JRSPay.loading_messages();
    messages.shuffle();

    const set_new_title = function() {
        $('.modal-title', $mdl)
            .hide()
            .text(messages[message_count] + '...')
            .fadeIn(400)
        message_count++
    }

    set_new_title();
    let modal_timer = setInterval(set_new_title, 4000)

    loadBillsData().then(() => {
        modal_timer = undefined
        $mdl.modal('hide');
    })
}

