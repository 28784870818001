import $ from "jquery";
import JRSPay from '../JRSPay';
import {DateTime} from 'luxon';
import axios from 'axios';

export default function(){

    const $form = $('#frmBillPaymentInput');
    const $table = $('#tblBillPayChecklist');
    const $modal = $('#modalBillPayment');
    const $modalSeePayments = $('#modalSeePayments');
    const $tblPayments = $('#tblPayments');
    const $tblBPCLoginOverviewMonth = $('#tblBPCLoginOverviewMonth');
    const $bplMonths = $('#bplMonths');
    let $dataTable;

    const nmonth = function(){
        return +$('li.active', $bplMonths).data('month');
    };

    const month_name = n => (new Date(2009, n - 1, 10)).toLocaleString('default', { month: 'long' })

    const loadFormInputs = function() {
        axios.get('/api/v1/settings/user/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const user of response.data) {
                    $('#login_id', $form).append(
                        $('<option>').attr('value', user.id).text(user.name)
                    );
                }
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            info: false,
            ordering: false,
            paging: false,
            searching: false,
            language: {
                zeroRecords: 'No bills in the system yet.'
            },
            columns: [{
                title: 'Bill',
                data: 'name',
                width: '20%',
                render: function (data, type) {
                    if (type === 'display') {
                        return data + ' <a class="btn btn-default btn-td-on-hover pull-right btn-xs" href="/expenses/bills/" role="button" rel="noreferrer">Edit <i class="fa fa-external-link-square"></i></a>';
                    }
                    return data;
                }
            }, {
                title: 'Company',
                data: 'company',
                width: '30%',
                render: function (data, type, row) {
                    if (type === 'display') {
                        if (!!row.website) {
                            let website = row.website;
                            if ('http' !== website.substring(0, 4)) {
                                website = '//' + website;
                            }
                            return data + ' <a class="btn btn-default pull-right btn-xs" href="' + website + '" role="button" rel="noreferrer" target="_blank"><span class="btn-td-on-hover">Open</span> <i class="fa fa-external-link"></i></a>';
                        }
                    }
                    return data;
                }
            }, {
                title: 'Owner',
                data: 'user',
                width: '24px',
            }, {
                title: 'Date Due',
                data: 'date_due',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return DateTime.fromSQL(data).toFormat('M/d')
                    }
                    return data;
                }
            }, {
                title: 'Total Payments',
                data: 'amount',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Date Paid',
                data: 'date_paid',
                class: 'text-right',
                render: function(data, type, row) {
                    if (type === 'display') {
                        if (row.is_nothing_due) {
                            return 'NOTHING DUE';
                        }
                        return data === null ? '' : DateTime.fromSQL(data).toFormat('M/d')
                    }
                    return data;
                }
            }, {
                title: ' ',
                data: 'name',
                class: 'text-right',
                render: function(data, type, row) {
                    if (type === 'display') {
                        if (!row.date_paid && !row.is_nothing_due) {
                            return ' <button class="btn btn-primary btn-xs btnMakePayment" role="button" >Make Payment</button>';
                        }
                        return ' <button class="btn btn-default btn-xs btnSeePayments" role="button" >View Details</button>';
                    }
                    return data;
                }
            }],
            data: [],
            rowCallback: function (row, data) {
                if (data.past_due) {
                    $(row).addClass('danger')
                }
            }
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();
        $('tbody', $tblBPCLoginOverviewMonth).empty();
        $('.tdTotal', $tblBPCLoginOverviewMonth).empty();

        axios.get('/api/v1/expenses/bill-pay-checklist/?' + $.param({
            fyear: JRSPay.fyear(),
            nmonth: nmonth()
        }))
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })

        axios.get('/api/v1/expenses/bill-pay-checklist/month-overview.php?' + $.param({
            fyear: JRSPay.fyear(),
            nmonth: nmonth()
        }))
            .then(response => {
                response = response.data;
                console.log(response);

                let total = 0;
                for (let user of response.data) {
                    total += parseFloat(user.amount);

                    let $tr = $('<tr>');
                    $('<td>').text(user.user).appendTo($tr);
                    $('<td>').addClass('text-right').text(formatMoney(user.amount)).appendTo($tr);
                    $tr.appendTo($('tbody', $tblBPCLoginOverviewMonth));
                }
                $('.tdTotal', $tblBPCLoginOverviewMonth).text(formatMoney(total));
            })
    };

    const loadMakePaymentModal = function(bill_data) {
        $('#btnFormDelete').hide();
        $('#btnNothingDue').show();
        $('#bill_id', $form).val(bill_data.id);
        $('#bill_payment_id', $form).val(0);
        $('#amount', $form).val('');
        $('#date_due', $form).val(bill_data.date_due);
        $('#date_paid', $form).val(bill_data.date_due);
        $('#login_id', $form).val(JRSPay.current_user_id());
        $('#notes', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Make Payment - ' + bill_data.name + ' ' + month_name(nmonth()) + '/' + JRSPay.fyear())

        $('.divLinkedNoteAlert', $form).hide();
        if (bill_data.note_payable_id > 0) {
            $('.divLinkedNoteAlert', $form).show();
        }

        $modal.find('.spnPreviousPayment')
            .empty()
            .append([
                $('<i class="fa fa-info-circle">'),
                ' No payments yet'
            ])
            .show()

        axios.get('/api/v1/expenses/bill/payment/previous-payment.php?' + $.param({
            bill_id: bill_data.id,
            date: bill_data.date_due
        }))
            .then(response => {
                let data = response.data.data

                if (!!data) {
                    $modal.find('.spnPreviousPayment')
                        .empty()
                        .append([
                            $('<i class="fa fa-info-circle">'),
                            ' Last payment was ' + formatMoney(data.amount) + ' on ' + DateTime.fromSQL(data.date_paid).toFormat('M/d/y')
                        ])
                }
            })
    }

    const loadSeePaymentsModal = function(bill_data) {
        $modalSeePayments.modal('show');
        $modalSeePayments.find('.modal-title').text(bill_data.name + ' - ' + month_name(nmonth()) + '/' + JRSPay.fyear() + ' Payments')
        $modalSeePayments.find('.alert-nothing-due').hide();
        $('tbody', $tblPayments).empty();
        $tblPayments.hide();
        $('#btnSomethingDue').data('bill_id', '');

        const $btnAddPayment = $('.btnAddPayment', $modalSeePayments)
        $btnAddPayment.hide().data({
            bill_data: bill_data
        });

        if (bill_data.is_nothing_due) {
            $('#btnSomethingDue').data('bill_id', bill_data.id);
            $modalSeePayments.find('.alert-nothing-due').show();
        }
        else {
            $tblPayments.show();
            $btnAddPayment.show();

            axios.get('/api/v1/expenses/bill/payment/list.php?' + $.param({
                bill_id: bill_data.id,
                nmonth: nmonth(),
                fyear: JRSPay.fyear()
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);
                    let payments = response.data;

                    if (payments.length > 0) {
                        for (const payment of payments) {
                            $('tbody', $tblPayments).append($('<tr>').append([
                                $('<td>').text(DateTime.fromSQL(payment.date_paid).toLocaleString()),
                                $('<td>').text(formatMoney(payment.amount)),
                                $('<td>').text(payment.user),
                                $('<td>').text(payment.notes),
                                $('<td>').addClass('text-right').append(
                                    $('<button>').addClass('btn btn-default btn-xs btnEditPayment').html('Edit <i class="fa fa-edit"></i>').data({
                                        bill_data: bill_data,
                                        bill_payment_id: payment.id
                                    })
                                ),
                            ]))
                        }
                    } else {
                        $tblPayments.append('<caption>No payments</caption>');
                    }
                })
        }
    }

    const loadEditPaymentModal = function(bill_payment_id, bill_data) {
        console.log(bill_data)
        $('#btnFormDelete').show();
        $('#btnNothingDue').hide();
        $('#bill_id', $form).val(bill_data.id);
        $('#bill_payment_id', $form).val(bill_payment_id);
        $('#date_due', $form).val(bill_data.date_due);
        $('#date_paid', $form).val(bill_data.date_due);
        $('#login_id', $form).val(JRSPay.current_user_id());
        $('#notes', $form).val('');
        $('#amount', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text((bill_payment_id > 0 ? 'Edit Payment' : 'Add Payment') + ' - ' + bill_data.name + ' ' + month_name(nmonth()) + '/' + JRSPay.fyear())

        $('.divLinkedNoteAlert', $form).hide();
        if (bill_data.note_payable_id > 0) {
            $('.divLinkedNoteAlert', $form).show();
        }

        $modal.find('.spnPreviousPayment').hide();

        axios.get('/api/v1/expenses/bill/payment/?' + $.param({
            bill_id: bill_data.id,
            bill_payment_id: bill_payment_id,
        }))
            .then(response => {
                response = response.data;
                console.log(response);
                let payment = response.data;
                if (!payment) {
                    return;
                }

                $('#amount', $form).val(payment.amount);
                $('#date_due', $form).val(payment.date_due);
                $('#date_paid', $form).val(payment.date_paid);
                $('#login_id', $form).val(payment.login_id);
                $('#notes', $form).val(payment.notes);
            })
    }

    const setNothingDue = function(bill_id, nothing_due) {

        axios.patch('/api/v1/expenses/bill/', {
            action: 'nothing-due',
            nothing_due: !!nothing_due,
            bill_id: bill_id,
            fyear: JRSPay.fyear(),
            nmonth: nmonth()
        })
            .then(response => {
                response = response.data;
                console.log(response);

                getTableData();
                $modal.modal('hide');
                $modalSeePayments.modal('hide');
            })
            .catch(error => {
                console.error(error.response.data.message);
                alert('Something went wrong: ' + error.response.data.message)
            })
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        // pagination
        $('li > a', $bplMonths).click(function () {
            let month_clicked = $(this).parent().data('month');
            if (month_clicked === 'prev') {
                month_clicked = nmonth() - 1;
            } else if (month_clicked === 'next') {
                month_clicked = nmonth() + 1;
            }

            if (month_clicked < 1 || month_clicked > 12) {
                return;
            }
            if (month_clicked === nmonth()) {
                return;
            }

            $('li', $bplMonths).removeClass('active');
            $('li[data-month=' + month_clicked + ']', $bplMonths).addClass('active');
            getTableData()
        });

        $dataTable.on('click', 'tbody .btnMakePayment', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadMakePaymentModal(data)
        });

        $dataTable.on('click', 'tbody .btnSeePayments', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadSeePaymentsModal(data)
        });

        $tblPayments.on('click', '.btnEditPayment', function(e){
            let $this = $(e.currentTarget);
            $modalSeePayments.modal('hide');
            $modalSeePayments.modal('resetAdjustments')
            $modalSeePayments.modal('resetScrollbar');
            loadEditPaymentModal($this.data('bill_payment_id'), $this.data('bill_data'));
        });

        $tblPayments.on('click', '.btnAddPayment', function(e){
            let $this = $(e.currentTarget);
            $modalSeePayments.modal('hide');
            $modalSeePayments.modal('resetAdjustments')
            $modalSeePayments.modal('resetScrollbar');
            loadEditPaymentModal(0, $this.data('bill_data'));
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to delete this payment?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/expenses/bill/payment/', {
                data: {
                    bill_id: $('#bill_id', $form).val(),
                    bill_payment_id: $('#bill_payment_id', $form).val()
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $('#btnNothingDue').click(function() {
            setNothingDue($('#bill_id', $form).val(), true);
        });

        $('#btnSomethingDue').click(function() {
            setNothingDue($(this).data('bill_id'), false);
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.nmonth = nmonth();
            payload.fyear = JRSPay.fyear();
            payload.amount = parseMoney(payload.amount);

            let request;
            if (payload.bill_payment_id > 0) {
                request = axios.put('/api/v1/expenses/bill/payment/', payload)
            } else {
                request = axios.post('/api/v1/expenses/bill/payment/', payload)
            }

            request.then(response => {
                response = response.data;
                console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
