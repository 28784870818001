import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios'

export default function(){

    const $form = $('#frmAccountInput');
    const $table = $('#tblAccounts');
    const $modal = $('#modalAccount');
    const $modalBalances = $('#modalAccountBalances');
    const $frmAccountBalances = $('#frmAccountBalances');

    let $dataTable;

    const loadFormInputs = function() {
        $('#fyear', $form).append($('#sidebar-fyear').find('option').clone())

        axios.get('/api/v1/settings/user/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);

                for (const user of response.data) {
                    $('#owner_login_id', $form).append(
                        $('<option>').attr('value', user.id).text(user.name)
                    );
                }
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No accounts in the system yet.'
            },
            columns: [{
                title: 'Account Name',
                data: 'name',
                // width: '20%',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Status',
                data: 'status',
            }, {
                title: 'Owner',
                data: 'owner',
            }, {
                title: 'Balance',
                data: 'balance',
                class: 'text-right',
                render: function(data, type, row) {
                    if (type === 'display') {
                        return '<button class="btn-link btnEditBalances" >' + formatMoney(data) + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/current-assets/account-cash/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#account_cash_id', $form).val(0);
        $('#name', $form).val('');
        $('#account_opened', $form).val('');
        $('#account_closed', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#account_cash_id', $form).val(id);

            axios.get('/api/v1/current-assets/account-cash/?' + $.param({
                account_cash_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const ar = response.data;
                    $('#name', $form).val(ar.name);
                    $('#account_opened', $form).val(ar.account_opened);
                    $('#account_closed', $form).val(!ar.account_closed ? '' : ar.account_closed);
                    $('#owner_login_id', $form).val(ar.owner_login_id);
                    $modal.find('.modal-title').text('Edit Account Receivable');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Account Receivable')
                })
        }
        else {
            $modal.find('.modal-title').text('New Account Receivable')
        }
    }

    const loadEditBalances = function(id, name) {
        const fyear = JRSPay.fyear();
        const $tbody = $modalBalances.find('tbody');

        $modalBalances.find('.modal-title').text(name + ' Balances')
        $modalBalances.find('label[for=ending_balance]').text(fyear + ' Ending Balance')
        $('#ending_balance', $modalBalances).attr('placeholder', fyear + ' Ending Balance')
        $('#ending_balance', $modalBalances).val('')
        $tbody.empty();

        $modalBalances.modal('show');

        if (id === 0) {
            alert("what?")
        }

        $('[name=account_cash_id]', $frmAccountBalances).val(id);

        axios.get('/api/v1/current-assets/account-cash/balance.php?' + $.param({
            account_cash_id: id,
            fyear: JRSPay.fyear()
        }))
            .then(response => {
                response = response.data;
                console.log(response);

                const balances = response.data;
                $('#ending_balance', $modalBalances).val(balances[0].ending_balance.toFixed(2));

                const past_balances = balances.slice(1, 11);
                for (const bal of past_balances) {
                    let $tr = $('<tr>');
                    $('<td>').text(bal.fyear).appendTo($tr);
                    $('<td>').text(formatMoney(bal.ending_balance)).appendTo($tr);
                    $tr.appendTo($tbody);
                }

                if (balances.length > 11) {
                    let $tr = $('<tr>');
                    $('<td>').text('...').appendTo($tr);
                    $('<td>').text('...').appendTo($tr);
                    $tr.appendTo($tbody);
                }
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $dataTable.on('click', 'tbody .btnEditBalances', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadEditBalances(data.id, data.name)
        });

        $('#btnAddAccount').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this W-2 from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/current-assets/account-cash/', {
                data: {
                    account_cash_id: $('[name=account_cash_id]', $form).val()
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();

            axios.post('/api/v1/current-assets/account-cash/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

        $frmAccountBalances.submit(function(evt) {
            evt.preventDefault();

            let payload = $(this).serializeObject();
            payload.ending_balance = parseMoney(payload.ending_balance);
            payload.fyear = JRSPay.fyear();

            axios.post('/api/v1/current-assets/account-cash/balance.php', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modalBalances.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
