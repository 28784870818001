import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios'

export default function(){

    const $form = $('#frmAssetInput');
    const $table = $('#tblAssets');
    const $modal = $('#modalAsset');
    const $modalAssetValues = $('#modalAssetValues');
    const $frmAssetValues = $('#frmAssetValues');
    const $modalAssetMileage = $('#modalAssetMileage');
    const $frmAssetMileage = $('#frmAssetMileage');

    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/settings/user/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);

                $('#owner_login_id', $form).append(
                    $('<option>').attr('value', 0).text('Joint')
                );

                for (const user of response.data) {
                    $('#owner_login_id', $form).append(
                        $('<option>').attr('value', user.id).text(user.name)
                    );
                }
            });

        axios.get('/api/v1/liabilities/note-payable/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                let $active = $('<optgroup>').attr('label', 'Active');
                let $inactive = $('<optgroup>').attr('label', 'Inactive');

                for (const note of response.data) {
                    let $opt = $('<option>').attr('value', note.id).text(note.name)

                    if (note.account_closed === null) {
                        $opt.appendTo($active);
                    } else if ((new Date(note.account_closed)) > (new Date())) {
                        $opt.appendTo($active);
                    } else {
                        $opt.appendTo($inactive);
                    }
                }

                $('#note_payable_id', $form).append([
                    $('<option>').attr('value', 0).text('Not Applicable'),
                    $active,
                    $inactive
                ])
            });

        axios.get('/api/v1/noncurrent-assets/fixed/type.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const type of response.data) {
                    $('#fixed_asset_type_id', $form).append(
                        $('<option>').attr('value', type.id).text(type.name)
                    );
                }
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No fixed assets in the system yet.'
            },
            columns: [{
                title: 'Asset Name',
                data: 'name',
                width: '20%',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Status',
                data: 'status',
            }, {
                title: 'Owner',
                data: 'owner',
            }, {
                title: 'Acquisition Cost',
                data: 'purchase_price',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Depreciation /yr',
                data: 'depreciation_rate',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Current Mileage',
                data: 'mileage',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        if (data === null) {
                            return '';
                        }
                        return '<button class="btn-link btnEditMileage" >' + data.toLocaleString() + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Market Value',
                data: 'asset_value',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnEditBalances" >' + formatMoney(data) + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Equity',
                data: 'equity',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Gain on Dispossession',
                data: 'gain_on_sale',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        if (data === null) {
                            return '';
                        }
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/noncurrent-assets/fixed/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);

                let data = response.data.filter(f => +f.fixed_asset_type_id !== 2)
                $dataTable.api().rows.add(data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#fixed_asset_id', $form).val(0);
        $('#name', $form).val('');
        $('#fixed_asset_type_id', $form).val(0).change();
        $('#owner_login_id', $form).val(0);
        $('#purchase_date', $form).val('');
        $('#purchase_price', $form).val('');
        $('#purchase_mileage', $form).val('');
        $('#selling_date', $form).val('');
        $('#selling_price', $form).val('');
        $('#note_payable_id', $form).val(0);
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#fixed_asset_id', $form).val(id);

            axios.get('/api/v1/noncurrent-assets/fixed/?' + $.param({
                fixed_asset_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const ar = response.data;
                    $('#name', $form).val(ar.name);
                    $('#fixed_asset_type_id', $form).val(ar.fixed_asset_type_id).change();
                    $('#owner_login_id', $form).val(ar.owner_login_id);
                    $('#purchase_date', $form).val(ar.purchase_date);
                    $('#purchase_price', $form).val(ar.purchase_price.toFixed(2));
                    $('#purchase_mileage', $form).val(!ar.purchase_mileage ? '' : ar.purchase_mileage.toFixed(0));
                    $('#selling_date', $form).val(!ar.selling_date ? '' : ar.selling_date);
                    $('#selling_price', $form).val(!ar.selling_price ? '' : ar.selling_price.toFixed(2));
                    $('#note_payable_id', $form).val(ar.note_payable_id);
                    $modal.find('.modal-title').text('Edit Fixed Asset');
                })
                .catch(error => {
                    console.error(error.response.data.message)
                    $modal.find('.modal-title').text('New Fixed Asset')
                })
        }
        else {
            $modal.find('.modal-title').text('New Fixed Asset')
        }
    }

    const loadEditBalances = function(id, name) {
        const fyear = JRSPay.fyear();
        const $tbody = $modalAssetValues.find('tbody');

        $modalAssetValues.find('.modal-title').text(name + ' Market Value')
        $modalAssetValues.find('label[for=asset_value]').text(fyear + ' Market Value')
        $('#asset_value', $modalAssetValues).attr('placeholder', fyear + ' Market Value')
        $('#asset_value', $modalAssetValues).val('')
        $tbody.empty();

        $modalAssetValues.modal('show');

        if (id === 0) {
            alert("what?")
        }

        $('[name=fixed_asset_id]', $frmAssetValues).val(id);

        axios.get('/api/v1/noncurrent-assets/fixed/asset-value.php?' + $.param({
            fixed_asset_id: id,
            fyear: JRSPay.fyear()
        }))
            .then(response => {
                response = response.data;
                console.log(response);

                const balances = response.data;
                $('#asset_value', $modalAssetValues).val(balances[0].asset_value.toFixed(2));

                const past_balances = balances.slice(1, 11);
                for (const bal of past_balances) {
                    let $tr = $('<tr>');
                    $('<td>').text(bal.fyear).appendTo($tr);
                    $('<td>').text(formatMoney(bal.asset_value)).appendTo($tr);
                    $tr.appendTo($tbody);
                }

                if (balances.length > 11) {
                    let $tr = $('<tr>');
                    $('<td>').text('...').appendTo($tr);
                    $('<td>').text('...').appendTo($tr);
                    $tr.appendTo($tbody);
                }
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
    }

    const loadEditMileage = function(id, name) {
        const fyear = JRSPay.fyear();
        const $tbody = $modalAssetMileage.find('tbody');

        $modalAssetMileage.find('.modal-title').text(name + ' Mileage')
        $modalAssetMileage.find('label[for=mileage]').text(fyear + ' Mileage')
        $('#mileage', $modalAssetMileage).attr('placeholder', fyear + ' Mileage')
        $('#mileage', $modalAssetMileage).val('')
        $tbody.empty();

        $modalAssetMileage.modal('show');

        if (id === 0) {
            alert("what?")
        }

        $('[name=fixed_asset_id]', $frmAssetMileage).val(id);

        axios.get('/api/v1/noncurrent-assets/fixed/mileage.php?' + $.param({
            fixed_asset_id: id,
            fyear: JRSPay.fyear()
        }))
            .then(response => {
                response = response.data;
                console.log(response);

                const balances = response.data;
                $('#mileage', $modalAssetMileage).val(parseInt(balances[0].mileage));

                const past_balances = balances.slice(1, 11);
                for (const bal of past_balances) {
                    let $tr = $('<tr>');
                    $('<td>').text(bal.fyear).appendTo($tr);
                    $('<td>').text(bal.mileage.toLocaleString()).appendTo($tr);
                    $tr.appendTo($tbody);
                }

                if (balances.length > 11) {
                    let $tr = $('<tr>');
                    $('<td>').text('...').appendTo($tr);
                    $('<td>').text('...').appendTo($tr);
                    $tr.appendTo($tbody);
                }
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $dataTable.on('click', 'tbody .btnEditBalances', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadEditBalances(data.id, data.name)
        });

        $dataTable.on('click', 'tbody .btnEditMileage', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadEditMileage(data.id, data.name)
        });

        $('#fixed_asset_type_id').change(function() {
            const value = +this.value || 0;
            if (value === 1) {
                $('.car-visible', $form).show();
            } else {
                $('.car-visible', $form).hide();
            }
        });

        $('#btnAddAsset').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this asset from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/noncurrent-assets/fixed/', {
                data: {
                    fixed_asset_id: $('#fixed_asset_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.purchase_price = parseMoney(payload.purchase_price);
            payload.selling_price = !payload.selling_price ? null : parseMoney(payload.selling_price);

            axios.post('/api/v1/noncurrent-assets/fixed/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

        $frmAssetValues.submit(function(evt) {
            evt.preventDefault();

            let payload = $(this).serializeObject();
            payload.asset_value = parseMoney(payload.asset_value);
            payload.fyear = JRSPay.fyear();

            axios.post('/api/v1/noncurrent-assets/fixed/asset-value.php', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modalAssetValues.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

        $frmAssetMileage.submit(function(evt) {
            evt.preventDefault();

            let payload = $(this).serializeObject();
            payload.mileage = parseInt(payload.mileage);
            payload.fyear = JRSPay.fyear();

            axios.post('/api/v1/noncurrent-assets/fixed/mileage.php', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modalAssetMileage.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
