import JRSPay from './JRSPay';
import $ from "jquery";
import axios from 'axios'

export default function(){

    $('#btnDownloadBS').click(function() {
        $('#btnDownloadBS').find('i').attr('class', 'fa fa-circle-o-notch fa-spin');

        // todo change this to new endpoint
        $.post('/api/v0/dashboard/download_balance_sheet.json.php')
            .done(response => {
                //console.log( response );
                if (response.success == 1) {
                    $('#fraDownloadBS').attr('src', response.file_name);
                } else {
                    console.error(response.err_msg);
                    alert(response.err_msg);
                }
            })
            .fail(j => {
                console.error(j);
                alert(j.responseText);
            })
            .then(() => {
                $('#btnDownloadBS').find('i').attr('class', 'fa fa-download');
            });
    });

    // todo change this to new endpoint
    axios.get('/api/v1/balance-sheet/legacy.php')
        .then(response => {
            response = response.data;
            console.log(response);

            const current_year = JRSPay.fyear();
            const years = response.data.years;
            const style = response.data.style;
            const values = response.data.values;

            const $table = $('#tblBalanceSheet');
            const $thead = $('<thead>');
            const $tbody = $('<tbody>');

            const getEmptyRow = function() {
                let $tr = $('<tr>');
                $tr.append($('<th>'));
                for (let y of years) {
                    $tr.append($('<th>').html('&nbsp;'));
                }
                return $tr;
            }

            const formatValue = function(value, data_type) {
                if (data_type === 'C') {
                    return formatMoney(value)
                } else if (data_type === 'R') {
                    return value.toFixed(2)
                } else if (data_type === 'P') {
                    return formatPerc(value)
                } else {
                    return formatMoney(value).replace('$', '');
                }
            }

            // thead

            let $thead_tr = $('<tr>')
            $thead_tr.append($('<th>'));

            for (let y of years) {
                $thead_tr.append($('<th>').attr({
                    class: 'text-center' + (y > current_year ? ' text-muted' : '')
                }).text(y));
            }
            $thead_tr.appendTo($thead);

            // tbody

            let current_group = '';

            for (let j = 0; j < style.length; j++) {

                if (current_group != style[j].report_field_group) {
                    current_group = style[j].report_field_group;
                    let $header = $('<tr>')
                    $header.append($('<th>').text(current_group))
                    for (let y of years) {
                        $header.append($('<th>'));
                    }
                    $header.appendTo($tbody);
                }

                let $main_row = $('<tr>');

                $('<td>').attr({
                    class: ['text-left', style[j]['bold_flag'], style[j]['italic_flag']].join(' '),
                }).html(style[j]['indent_flag'] + " " + style[j]['field_header'])
                    .appendTo($main_row);

                for (let y of years) {
                    $('<td>').attr({
                        class: ['text-right', (y > current_year ? 'text-muted' : ''), (style[j]['space_after'] === "1" ? 'border-top-dark' : '')].join(' ')
                    })
                        .text(formatValue(values[y][style[j]['field_name']]))
                        .appendTo($main_row);

                }

                $main_row.appendTo($tbody);

                if (style[j]['space_after'] === "1") {
                    getEmptyRow().appendTo($tbody);
                }
            }

            // done

            $table.append([
                $thead,
                $tbody
            ]);
        })

};
