import $ from "jquery";
import JRSPay from '../JRSPay';
import Chart from 'chart.js/auto'
import axios from 'axios';

export default function(){

    let mrsInvestmentPerformance;
    const $form = $('#frmInvestmentInput');
    const $table = $('#tblInvestments');
    const $modal = $('#modalInvestment');

    let $dataTable;

    const loadFormInputs = function() {
        $('#fyear', $form).append($('#sidebar-fyear').find('option').clone())

        axios.get('/api/v1/settings/user/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                $('#owner_login_id', $form).append(
                    $('<option>').attr('value', 0).text('Joint')
                );

                for (const user of response.data) {
                    $('#owner_login_id', $form).append(
                        $('<option>').attr('value', user.id).text(user.name)
                    );
                }
            });

        axios.get('/api/v1/noncurrent-assets/investment/type.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const type of response.data) {
                    $('#investment_type_id', $form).append(
                        $('<option>').attr('value', type.id).text(type.name)
                    );
                }
            });
    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No investments in the system yet.'
            },
            columns: [{
                title: 'Name',
                data: 'name',
                width: '17%',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Institution',
                data: 'institution',
            }, {
                title: 'Owner',
                data: 'owner',
            }, {
                title: 'Type',
                data: 'investment_type',
                width: '150px'
            }, {
                title: 'Contributions',
                data: 'contributions',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Income',
                data: 'income',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Balance',
                data: 'balance',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data) + ' <button class="btn btn-default btn-xs btnEditBalance">Edit <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'ROI',
                data: 'roi',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatPerc(data);
                    }
                    return data;
                }
            }, {
                title: 'ROR',
                data: 'ror',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatPerc(data);
                    }
                    return data;
                }
            }, {
                title: 'Cost%',
                data: 'cost_percentage',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatPerc(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/noncurrent-assets/investment/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })

        loadInvestmentCharts();
    };

    const loadFormData = function(id) {
        $('#investment_id', $form).val(0);
        $('#name', $form).val('');
        $('#institution', $form).val('');
        $('#investment_type_id', $form).val(0);
        $('#account_opened', $form).val('');
        $('#account_closed', $form).val('');
        $('#owner_login_id', $form).val(0);
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#investment_id', $form).val(id);

            axios.get('/api/v1/noncurrent-assets/investment/?' + $.param({
                investment_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const ar = response.data;
                    $('#name', $form).val(ar.name);
                    $('#institution', $form).val(ar.institution);
                    $('#investment_type_id', $form).val(ar.investment_type_id);
                    $('#account_opened', $form).val(ar.account_opened);
                    $('#account_closed', $form).val(!ar.account_closed ? '' : ar.account_closed);
                    $('#owner_login_id', $form).val(ar.owner_login_id);
                    $modal.find('.modal-title').text('Edit Investment');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Investment')
                })
        }
        else {
            $modal.find('.modal-title').text('New Investment')
        }
    }

    const loadInvestmentCharts = function(investment_id = 0, name = '') {
        const $metrics_table = $('#metrics-table');
        const $investment_profile_wrapper = $('#investment_profile_wrapper');
        $('tbody tr:not(:first-child) td.value:not(.external)', $metrics_table).attr('contenteditable', (investment_id > 0));
        $('.investment_id', $investment_profile_wrapper).text(investment_id);

        if (investment_id > 0) {
            $('#spnChartTitle').text(' - ' + name);
            $('#btnBackToOverall').show();
        } else {
            $('#spnChartTitle').text('');
            $('#btnBackToOverall').hide();
        }

        const render_metric = function(current_value, prev_value, $metric, is_reverse = false) {
            $('.value', $metric).text(formatMoney(current_value));
            $('.value', $metric).data('value_org', current_value);
            let new_class = get_class((is_reverse ? -1 : 1) * (current_value - prev_value))
            let new_caret = get_caret(current_value - prev_value)
            let incr_perc = get_incr_perc(current_value, prev_value);
            $('.percentage span', $metric).text(formatPerc(incr_perc));
            $('.percentage', $metric).attr('class', 'percentage ' + new_class);
            $('.percentage i', $metric).attr('class', 'fa ' + new_caret);
        };

        const render_footer_metric = function(current_value, prev_value, $metric, is_perc = false, is_reverse = false) {
            let new_class = get_class((is_reverse ? -1 : 1) * (current_value - prev_value))
            let new_caret = get_caret(current_value - prev_value)
            let incr_perc = get_incr_perc(current_value, prev_value);
            $('.description-header', $metric).text(is_perc ? formatPerc(current_value) : formatMoney(current_value));
            $('.description-percentage .percentage', $metric).text(formatPerc(incr_perc));
            $('.description-percentage', $metric).removeClass(['text-muted', 'text-green', 'text-red']).addClass(new_class);
            $('.description-percentage i', $metric).attr('class', 'fa ' + new_caret);
        };

        axios.get('/api/v1/noncurrent-assets/investment/performance.php?' + $.param({
            investment_id: investment_id,
            fyear: JRSPay.fyear()
        }))
            .then(response => {
                response = response.data.data;
                console.log(response);

                mrsInvestmentPerformance.data.labels = response.data.map(d => d.fyear)
                mrsInvestmentPerformance.data.datasets[0].data = response.data.map(d => d.contributions);
                mrsInvestmentPerformance.data.datasets[1].data = response.data.map(d => d.balance);
                mrsInvestmentPerformance.update();

                const metrics = response.metrics;
                $('#metric-name .value').text(response.metrics.name || 'Overall');

                const cost_perc = parseFloat(metrics.fees) / parseFloat(metrics.balance);
                const cost_perc_prev = parseFloat(metrics.fees_prev) / parseFloat(metrics.balance_prev);

                render_metric(metrics.personal_contribution, metrics.personal_contribution_prev, $('#metric-personal_contribution'));
                render_metric(metrics.rollovers_transfers, metrics.rollovers_transfers_prev, $('#metric-rollovers_transfers'));
                render_metric(metrics.employer_contribution, metrics.employer_contribution_prev, $('#metric-employer_contribution'));
                render_metric(metrics.market_changes, metrics.market_changes_prev, $('#metric-market_changes'));
                render_metric(metrics.dividends, metrics.dividends_prev, $('#metric-dividends'));
                render_metric(metrics.fees, metrics.fees_prev, $('#metric-fees'), true);

                // footer
                render_footer_metric(metrics.balance, metrics.balance, $('#footer-balance'));
                render_footer_metric(metrics.income, metrics.income_prev, $('#footer-income'));
                render_footer_metric(metrics.ror, metrics.ror_prev, $('#footer-return'), true);
                render_footer_metric(cost_perc, cost_perc_prev, $('#footer-fees'), true, true);
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
    };

    const init = function() {

        $dataTable = initDataTable();

        $modal.modal({
            show: false
        });

        mrsInvestmentPerformance = new Chart(document.getElementById('chtInvestmentPerformance'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Contributions',
                    data: [],
                    showLine: true,
                    fill: 'origin',
                    tension: 0,
                }, {
                    label: 'Balance',
                    data: [],
                    showLine: true,
                    tension: 0.3,
                }],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });

        loadFormInputs();
        getTableData();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $dataTable.on('click', 'tbody .btnEditBalance', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadInvestmentCharts(data.id, data.name)
        });

        $('#btnAddInvestment').click(function(){
            loadFormData(0);
        });

        $('#btnBackToOverall').click(function() {
            loadInvestmentCharts(0)
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this note from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/noncurrent-assets/investment/', {
                data: {
                    investment_id: $('[name=investment_id]', $form).val()
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();

            axios.post('/api/v1/noncurrent-assets/investment/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

        $('#metrics-table').on('keydown focusout', 'td[contenteditable=true]', function (e) {
            const $this = $(e.currentTarget);
            const working = $this.data('working');
            const investment_id = +$('#investment_profile_wrapper .investment_id').text() || 0;
            if (investment_id === 0) {
                return;
            }
            if (working) {
                return;
            }

            if ((+e.keyCode === 13 || e.type === 'focusout')) {
                const type = $this.parent().attr('id').split('-')[1];
                const value = parseMoney($this.text());
                const value_org = parseMoney($this.data('value_org') || '');
                // console.log(type);
                // console.log(value_org);
                // console.log(value);

                if (value !== value_org) {
                    $this.data('working', true);
                    e.preventDefault();
                    if (e.type !== 'blur') {
                        console.log(type);
                        $this.blur();
                    }

                    axios.patch('/api/v1/noncurrent-assets/investment/', {
                        action: 'set-field',
                        fyear: JRSPay.fyear(),
                        field: type,
                        investment_id: investment_id,
                        value: value
                    })
                        .then(response => {
                            response = response.data;
                            console.log(response);
                            $this.data('value_org', value);
                            $this.text(formatMoney(value));

                            const name = $('#spnChartTitle').text().substring(3);
                            loadInvestmentCharts(investment_id, name);
                        })
                        .catch(error => {
                            console.error(error.response.data.message);
                            alert('Something went wrong: ' + error.response.data.message)
                        })
                        .finally(() => {
                            $this.data('working', false);
                        })
                } else {
                    $this.text(formatMoney(value_org));
                }
            }
        });
    };

    init();

};
