import $ from "jquery";
import axios from 'axios'
import JRSPay from '../JRSPay'

export default function(){

    const $form = $('#frmK1Input');
    const $table = $('#tblK1');
    const $modal = $('#modalK1');
    let $dataTable;

    const loadFormInputs = function() {
        $('#fyear', $form).val(JRSPay.fyear())

        axios.get('/api/v1/settings/office/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                let $active = $('<optgroup>').attr('label', 'Active');
                let $inactive = $('<optgroup>').attr('label', 'Inactive');

                for (const office of response.data) {
                    if (office.gigs_flag) {
                        continue;
                    }

                    let $opt = $('<option>').attr('value', office.id).text(office.name);

                    if (office.is_active) {
                        $opt.appendTo($active);
                    } else {
                        $opt.appendTo($inactive);
                    }
                }

                $('#office_id', $form).append([
                    $active,
                    $inactive
                ])
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No Schedule K-1 records in the system yet.'
            },
            columns: [{
                title: 'Form',
                data: 'office',
                width: '20%',
                render: function (data, type, row) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + row.fyear + ' - ' + row.office + ' <i class="fa fa-edit"></i></button>';

                    } else if (type === 'sort') {
                        return row.fyear + ' - ' + row.office;
                    }

                    return data;
                }
            }, {
                title: 'Tax Year',
                data: 'fyear',
                class: 'text-right',
            }, {
                title: 'Ordinary Business Income',
                data: 'ordinary_business_income',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Guaranteed Payments',
                data: 'guaranteed_payments',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Distributions',
                data: 'distributions',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/revenue/k1/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(fyear, office_id) {
        $('#ordinary_business_income', $form).val('');
        $('#guaranteed_payments', $form).val('');
        $('#distributions', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (fyear > 0 && office_id > 0) {
            $('#fyear', $form).val(fyear);
            $('#office_id', $form).val(office_id);

            axios.get('/api/v1/revenue/k1/?' + $.param({
                fyear: fyear,
                office_id: office_id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const k1 = response.data;
                    $('#ordinary_business_income', $form).val(k1.ordinary_business_income.toFixed(2));
                    $('#guaranteed_payments', $form).val(k1.guaranteed_payments.toFixed(2));
                    $('#distributions', $form).val(k1.distributions.toFixed(2));
                    $modal.find('.modal-title').text('Edit Schedule K-1 | ' + fyear + ' - ' + k1.office);
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Schedule K-1')
                })
        }
        else {
            $modal.find('.modal-title').text('New Schedule K-1')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.fyear, data.office_id)
        });

        $('#btnAddK1').click(function(){
            loadFormData(0, 0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this K-1 from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/revenue/k1/', {
                data: {
                    fyear: $('#fyear', $form).val(),
                    office_id: $('#office_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.ordinary_business_income = parseMoney(payload.ordinary_business_income);
            payload.guaranteed_payments = parseMoney(payload.guaranteed_payments);
            payload.distributions = parseMoney(payload.distributions);

            axios.post('/api/v1/revenue/k1/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
