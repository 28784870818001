import $ from "jquery";
import JRSPay from '../JRSPay';
import {DateTime} from 'luxon';
import axios from 'axios'

export default function(){

    const $form = $('#frmRevenueInput');
    const $table = $('#tblRevenue');
    const $modal = $('#modalRevenue');
    let $dataTable;

    const loadFormInputs = function() {

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No records for this year.'
            },
            columns: [{
                title: 'Pay Date',
                data: 'pay_date',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + DateTime.fromSQL(data).toLocaleString() + ' <i class="fa fa-edit"></i></button>';

                    }
                    return data;
                }
            }, {
                title: 'Description',
                data: 'description',
            }, {
                title: 'Gross Pay',
                data: 'gross_pay',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/revenue/misc/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#other_revenue_id', $form).val(0);
        $('#pay_date', $form).val('');
        $('#gross_pay', $form).val('');
        $('#description', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#other_revenue_id', $form).val(id);

            axios.get('/api/v1/revenue/misc/?' + $.param({
                other_revenue_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const gig = response.data;
                    $('#pay_date', $form).val(gig.pay_date);
                    $('#gross_pay', $form).val(gig.gross_pay.toFixed(2));
                    $('#client_id', $form).val(gig.client_id);
                    $('#description', $form).val(gig.description);
                    $modal.find('.modal-title').text('Edit Record');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Record')
                })
        }
        else {
            $modal.find('.modal-title').text('New Record')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddRevenue').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this record from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/revenue/misc/', {
                data: {
                    other_revenue_id: $('#other_revenue_id', $form).val()
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.gross_pay = parseMoney(payload.gross_pay);

            axios.post('/api/v1/revenue/misc/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
