import $ from "jquery";
import JRSPay from '../JRSPay';
import {DateTime} from 'luxon';
import axios from 'axios'

export default function(){

    const $form = $('#frmPaymentInput');
    const $table = $('#tblPayment');
    const $modal = $('#modalPayment');
    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/expenses/tax-payment-type/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const tpt of response.data) {
                    $('#tax_payment_type_id', $form).append(
                        $('<option>').attr('value', tpt.id).text(tpt.name)
                    )
                }

                $('#tax_payment_type_id', $form).val(1);
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No tax payments in the system yet.'
            },
            columns: [{
                title: 'Date Paid',
                data: 'date_paid',
                render: function(data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + DateTime.fromSQL(data).toLocaleString() + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Tax Year',
                data: 'tax_year',
            }, {
                title: 'Payment Type',
                data: 'tax_payment_type',
            }, {
                title: 'Amount',
                data: 'amount',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/expenses/tax-payment/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#tax_payment_id', $form).val(0);
        $('#amount', $form).val('');
        $('#date_paid', $form).val('');
        $('#tax_year', $form).val(JRSPay.fyear());
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#tax_payment_id', $form).val(id);

            axios.get('/api/v1/expenses/tax-payment/?' + $.param({
                tax_payment_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const payment = response.data;
                    $('#amount', $form).val(payment.amount.toFixed(2));
                    $('#date_paid', $form).val(payment.date_paid);
                    $('#tax_payment_type_id', $form).val(payment.tax_payment_type_id);
                    $('#tax_year', $form).val(payment.tax_year);
                    $modal.find('.modal-title').text('Edit Payment');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Payment')
                })
        }
        else {
            $modal.find('.modal-title').text('New Payment')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddPayment').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this payment from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/expenses/tax-payment/', {
                data: {
                    tax_payment_id: $('#tax_payment_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();

            axios.post('/api/v1/expenses/tax-payment/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
