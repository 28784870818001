import $ from "jquery";
import axios from 'axios'
import JRSPay from '../JRSPay';

export default function(){

    const $form = $('#frmW2Input');
    const $table = $('#tblW2');
    const $modal = $('#modalW2');
    let $dataTable;

    const loadFormInputs = function() {
        $('#fyear', $form).val(JRSPay.fyear())

        axios.get('/api/v1/settings/office/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                let $active = $('<optgroup>').attr('label', 'Active');
                let $inactive = $('<optgroup>').attr('label', 'Inactive');

                for (const office of response.data) {
                    if (office.gigs_flag) {
                        continue;
                    }

                    let $opt = $('<option>').attr('value', office.id).text(office.name);

                    if (office.is_active) {
                        $opt.appendTo($active);
                    } else {
                        $opt.appendTo($inactive);
                    }
                }

                $('#office_id', $form).append([
                    $active,
                    $inactive
                ])
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No Form W-2 records in the system yet.'
            },
            columns: [{
                title: 'Form',
                data: 'office',
                width: '20%',
                render: function (data, type, row) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm">' + row.fyear + ' - ' + row.office + ' <i class="fa fa-edit"></i></button>';

                    } else if (type === 'sort') {
                        return row.fyear + ' - ' + row.office;
                    }

                    return data;
                }
            }, {
                title: 'Tax Year',
                data: 'fyear',
                class: 'text-right',
            }, {
                title: 'Federal Income Wages',
                data: 'federal_income_wages',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'SS Wages',
                data: 'social_security_wages',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Federal Income Tax',
                data: 'federal_income_tax',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'SS Tax',
                data: 'social_security_wages',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Medicare Tax',
                data: 'medicare_tax',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Healthcare OOP',
                data: 'healthcare_oop',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, { title: 'Life Insurance Comp',
                data: 'life_insurance_comp',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/revenue/w2/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#w2_id', $form).val(0);
        $('#federal_income_wages', $form).val('');
        $('#social_security_wages', $form).val('');
        $('#federal_income_tax', $form).val('');
        $('#social_security_tax', $form).val('');
        $('#medicare_tax', $form).val('');
        $('#healthcare_oop', $form).val('');
        $('#life_insurance_comp', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#w2_id', $form).val(id);

            axios.get('/api/v1/revenue/w2/?' + $.param({
                w2_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const w2 = response.data;
                    $('#fyear', $form).val(w2.fyear);
                    $('#office_id', $form).val(w2.office_id);
                    $('#federal_income_wages', $form).val(w2.federal_income_wages.toFixed(2));
                    $('#social_security_wages', $form).val(w2.social_security_wages.toFixed(2));
                    $('#federal_income_tax', $form).val(w2.federal_income_tax.toFixed(2));
                    $('#social_security_tax', $form).val(w2.social_security_tax.toFixed(2));
                    $('#medicare_tax', $form).val(w2.medicare_tax.toFixed(2));
                    $('#healthcare_oop', $form).val(w2.healthcare_oop.toFixed(2));
                    $('#life_insurance_comp', $form).val(w2.life_insurance_comp.toFixed(2));
                    $modal.find('.modal-title').text('Edit Form W-2 | ' + w2.fyear + ' - ' + w2.office);
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Form W2')
                })
        }
        else {
            $modal.find('.modal-title').text('New Form W2')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddW2').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this W-2 from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/revenue/w2/', {
                data: {
                    w2_id: $('#w2_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.federal_income_wages = parseMoney(payload.federal_income_wages);
            payload.social_security_wages = parseMoney(payload.social_security_wages);
            payload.federal_income_tax = parseMoney(payload.federal_income_tax);
            payload.social_security_tax = parseMoney(payload.social_security_tax);
            payload.medicare_tax = parseMoney(payload.medicare_tax);
            payload.healthcare_oop = parseMoney(payload.healthcare_oop);
            payload.life_insurance_comp = parseMoney(payload.life_insurance_comp);

            axios.post('/api/v1/revenue/w2/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
