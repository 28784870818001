import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default function(){
    let chtPortfolioLine, chtPortfolioPie;
    let portfolio_data = {};
    let asset_types = [];

    function initPagination() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        const $ulYears = $('#ulYears');

        const current_year = function(){
            return +$('li.active', $ulYears).data('fyear');
        };

        // pagination
        $('li > a', $ulYears).click(function () {
            let year_clicked = $(this).parent().data('fyear');
            if (year_clicked === 'prev') {
                year_clicked = current_year() - 1;
            } else if (year_clicked === 'next') {
                year_clicked = current_year() + 1;
            }

            if (year_clicked < init_year || year_clicked > this_year) {
                return;
            }
            if (year_clicked === current_year()) {
                return;
            }

            $('li', $ulYears).removeClass('active');
            $('li[data-fyear=' + year_clicked + ']', $ulYears).addClass('active');
            loadPieCharts(year_clicked)
        });
    }

    function initCharts() {
        chtPortfolioPie = new Chart(document.getElementById('chtPortfolioPie'), {
            type: 'pie',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: context => ' ' + formatMoney(context.parsed),
                        },
                    },
                    title: {
                        display: true,
                        text: 'Investment by Type'
                    }
                }
            }
        });
        chtPortfolioLine = new Chart(document.getElementById('chtPortfolioLine'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        // type: 'logarithmic',
                        title: {
                            display: true
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        itemSort: (a, b) => b.raw - a.raw,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
    }

    function loadPieCharts(fyear) {
        chtPortfolioPie.data.labels = asset_types.map(t => t.name)
        // console.log(portfolio_data)
        // console.log(asset_types.map(t => portfolio_data[fyear]))

        const chart_data = asset_types.map(t => +(portfolio_data[fyear][t.name] || {}).balance || null)
        chtPortfolioPie.data.datasets = [{ data: chart_data }]
        chtPortfolioPie.update('none')

        // write distribution table
        const total = chart_data.sum()
        const $tbl = $('#tblDiversification tbody');
        $tbl.empty()
        $tbl.append(
            asset_types
                .map(t => {
                    let perc = +(portfolio_data[fyear][t.name] || {}).balance || null
                    if (perc !== null) {
                        perc /= total;
                    }
                    return [t.name, perc];
                })
                .filter(p => p[1] !== null)
                .sort((a, b) => b[1] - a[1])
                .map(p => {
                    return $('<tr>').append([
                        $('<th>').text(p[0]),
                        $('<td>').text(formatPerc(p[1])),
                    ])
                })
        )

    }

    function loadLineCharts() {
        chtPortfolioLine.options.scales.y.title.text = "Portfolio Value"

        chtPortfolioLine.data.labels = Object.keys(portfolio_data)
        chtPortfolioLine.data.datasets = asset_types.map(t => {
            return {
                label: t.name,
                data: []
            }
        });

        const assets = Object.values(portfolio_data)
        if (assets.length > 0) {
            for (let b of assets) {
                for (let bt of asset_types) {
                    let dataset = chtPortfolioLine.data.datasets.find(d => d.label === bt.name)
                    if (dataset) {
                        if (!b[bt.name]) {
                            dataset.data.push(null)
                        }
                        else {
                            dataset.data.push(+b[bt.name].balance)
                        }
                    }
                }
            }
        }

        chtPortfolioLine.update()

        const $tblRates = $('#tblRates tbody')
        $tblRates.empty()
        $tblRates.append(
            asset_types.map(t => {
                let tds = [];
                tds.push($('<th>').text(t.name))

                let count = 0;
                let rates = [[], [], []]
                const entries = Object.entries(portfolio_data)
                console.log(entries)

                for (let i = entries.length - 1; i >= entries.length - 6 && i >= 0; i--) {
                    const e = entries[i][1]
                    const exists = !!e[t.name]
                    let ror = null
                    if (exists) {
                        ror = e[t.name].ror
                    }

                    if (count < 1) {
                        rates[0].push(ror)
                    }
                    if (count < 3) {
                        rates[1].push(ror)
                    }
                    if (count < 5) {
                        rates[2].push(ror)
                    }
                    count++
                }

                console.log(t.name, rates)

                for (let r of Object.entries(rates)) {
                    // let c = abs_diffs[r[0]].mean()
                    r = r[1].mean()
                    tds.push($('<td class="text-right">').append($('<span>').addClass(r < 0 ? "text-danger" : "text-success").text((r > 0 ? '+' : '') + formatPerc(r))))
                }

                return $('<tr>').append(tds)
            })
        )
    }

    function loadPortfolioData() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        let promises = [];

        promises.push(
            axios.get('/api/v1/noncurrent-assets/investment/type.php')
                .then(response => {
                    asset_types = response.data.data;
                })
        )

        for (let y = init_year; y <= this_year; y++) {
            promises.push(
                axios.get('/api/v1/noncurrent-assets/investment/list.php?' + $.param({
                    fyear: y,
                }))
                    .then(response => {
                        return {
                            fyear: y,
                            data: response.data.data
                        }
                    })
            )
        }

        return Promise.all(promises)
            .then(responses => {

                for (const response of responses) {
                    if (!response) {
                        continue
                    }

                    let data = response.data;
                    let fyear = +response.fyear;

                    data = Object.groupBy(data, ({ investment_type }) => investment_type)
                    let grouped = {};

                    for (let [type, d] of Object.entries(data)) {
                        grouped[type] = d.reduce((acc, curr) => {
                            acc.balance += curr.balance
                            acc.ror_list.push(curr.ror)
                            return acc
                        }, {
                            balance: 0,
                            ror: null,
                            ror_list: []
                        });

                        grouped[type].ror = grouped[type].ror_list.mean()
                    }

                    console.log(grouped)

                    // add to main line chart
                    portfolio_data[fyear] = grouped;
                }

                loadPieCharts(this_year)
                loadLineCharts()
            })
    }

    function init() {

        // start
        const $mdl = $('#mdlLoading');
        $mdl.modal({
            show: true,
            backdrop: 'static',
        })

        let message_count = 0;
        let messages = JRSPay.loading_messages();
        messages.shuffle();

        const set_new_title = function() {
            $('.modal-title', $mdl)
                .hide()
                .text(messages[message_count] + '...')
                .fadeIn(400)
            message_count++
        }

        set_new_title();
        let modal_timer = setInterval(set_new_title, 4000)

        loadPortfolioData().then(() => {
            modal_timer = undefined
            $mdl.modal('hide');
        })
    }

    initPagination()
    initCharts()
    init()

}

