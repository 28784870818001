import $ from "jquery";
import JRSPay from '../JRSPay';
import {DateTime} from 'luxon';
import axios from 'axios'

export default function(){

    const $form = $('#frmPayoutInput');
    const $table = $('#tblPayout');
    const $modal = $('#modalPayout');
    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/current-assets/account-receivable/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);

                for (const account of response.data) {
                    $('#account_receivable_id', $form).append(
                        $('<option>').attr('value', account.id).text(account.name)
                    )
                }
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No payouts in the system yet.'
            },
            columns: [{
                title: 'Payout Amount',
                data: 'amount',
                // class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + formatMoney(data) + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Account',
                data: 'account_name',
            }, {
                title: 'Date Paid',
                data: 'date_paid',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return DateTime.fromSQL(data).toLocaleString('M/D/YYYY');
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/revenue/payout/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#payout_id', $form).val(0);
        $('#date_paid', $form).val('');
        $('#amount', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#payout_id', $form).val(id);

            axios.get('/api/v1/revenue/payout/?' + $.param({
                payout_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const payout = response.data;
                    $('#date_paid', $form).val(payout.date_paid);
                    $('#amount', $form).val(payout.amount);
                    $('#account_receivable_id', $form).val(payout.account_receivable_id);
                    $modal.find('.modal-title').text('Edit Payout');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Payout')
                })
        }
        else {
            $modal.find('.modal-title').text('New Payout')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddPayout').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this payout from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/revenue/payout/', {
                data: {
                    payout_id: $('#payout_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.amount = parseMoney(payload.amount);

            axios.post('/api/v1/revenue/payout/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
