import $ from "jquery";

export default (function(){
    const init_fyear = +$('#sidebar-init_fyear').val() || 0
    const current_user_id = +$('body').data('current-user-id') || 0

    return {
        init_fyear: () => init_fyear,
        fyear: () => +$('#sidebar-fyear').val() || 0,
        current_user_id: () => current_user_id,
        loading_messages: () => [
            // "Loading data",
            "Consulting the narwhals",
            "Brewing coffee",
            "Preparing analysis",
            "Getting snacks",
            "Running hamsters",
            "Training monkeys",
            "Building civilization",
            "Inventing agriculture",
            "Traversing the Dagobah system",
            "Fueling the developers",
            "Coalescing horoscopes",
            "Aligning the planets",
            "Crunching the numbers",
            "Tending the mandrake root",
            "Bowing to Buckbeak",
            "Consulting the Quibbler",
            "Looking into the Mirror of Erised",
            "Finding the Sorcerer's Stone",
            "Stabbing Tom Riddle's diary",
            "Conjuring a Patronus",
            "Escaping Azkaban",
            "Putting your name in the Goblet of Fire",
            "Opening the Chamber of Secrets",
            "Bewitching the Triwizard Cup",
            "Stopping by 12 Grimmauld Place",
            "Battling for the Elder Wand",
            "Staring in the pensieve",
            "Reading tea leaves",
            "Blocking bludgers",
            "Seeking the snitch",
            "Looking for Platform 9¾",
            "Boarding the Hogwarts Express",
            "Nargels",
            "Perfecting parcel tongue",
            "Beseeching the Room of Requirement",
            "Freeing Dobby",
        ]
    }
})()
