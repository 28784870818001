import $ from "jquery";
import axios from 'axios'

export default function(){

    const $form = $('#frmClientInput');
    const $table = $('#tblClients');
    const $modal = $('#modalClient');
    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/settings/office/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                let $active = $('<optgroup>').attr('label', 'Active');
                let $inactive = $('<optgroup>').attr('label', 'Inactive');

                for (const office of response.data) {
                    if (!office.gigs_flag) {
                        continue;
                    }

                    let $opt = $('<option>').attr('value', office.id).text(office.name);

                    if (office.is_active) {
                        $opt.appendTo($active);
                    } else {
                        $opt.appendTo($inactive);
                    }
                }

                $('#office_id', $form).append([
                    $active,
                    $inactive
                ])
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No clients in the system yet.'
            },
            columns: [{
                title: 'Name',
                data: 'name',
                // class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Office',
                data: 'office',
                // class: 'text-right',
            }, {
                title: 'Status',
                data: 'is_active',
                // class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return data ? 'Active' : 'Inactive';
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/settings/client/list.php')
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#client_id', $form).val(0);
        $('#name', $form).val('');
        $('#contact_name', $form).val('');
        $('#contact_email', $form).val('');
        $('#is_active', $form).iCheck('check');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#client_id', $form).val(id);

            axios.get('/api/v1/settings/client/?' + $.param({
                client_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const client = response.data;
                    $('#name', $form).val(client.name);
                    $('#office_id', $form).val(client.office_id);
                    $('#contact_name', $form).val(client.contact_name);
                    $('#contact_email', $form).val(client.contact_email);
                    $('#is_active', $form).iCheck(client.is_active ? 'check' : 'uncheck');
                    $modal.find('.modal-title').text('Edit Client');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Client')
                })
        }
        else {
            $modal.find('.modal-title').text('New Client')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddClient').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this client from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/settings/client/', {
                data: {
                    client_id: $('#client_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();
            let payload = $form.serializeObject();
            payload.is_active = !!payload.is_active || false;

            axios.post('/api/v1/settings/client/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
