import $ from "jquery";
import JRSPay from '../JRSPay';
import {DateTime} from 'luxon';
import axios from 'axios'

export default function(){

    const $form = $('#frmBillInput');
    const $table = $('#tblBill');
    const $modal = $('#modalBill');
    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/expenses/bill-type/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const type of response.data) {
                    $('#bill_type_id', $form).append(
                        $('<option>').attr('value', type.id).text(type.name)
                    )
                }
            });

        axios.get('/api/v1/settings/user/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const user of response.data) {
                    $('#login_id', $form).append(
                        $('<option>').attr('value', user.id).text(user.name)
                    );
                }
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No bills in the system yet.'
            },
            columns: [{
                title: 'Description',
                data: 'name',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Company',
                data: 'company',
            }, {
                title: 'Status',
                data: 'status',
            }, {
                title: 'Type',
                data: 'bill_type',
            }, {
                title: 'Owner',
                data: 'user',
            }, {
                title: 'Date Opened',
                data: 'date_opened',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return DateTime.fromSQL(data).toLocaleString();
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/expenses/bill/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#bill_id', $form).val(0);
        $('#name', $form).val('');
        $('#company', $form).val('');
        $('#bill_type_id', $form).val(0);
        $('#day_of_month_due', $form).val('');
        $('#autopay_flag', $form).iCheck("uncheck");
        $('#login_id', $form).val(JRSPay.current_user_id());
        $('#website', $form).val('');
        $('#username', $form).val('');
        $('#date_opened', $form).val('');
        $('#date_closed', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#bill_id', $form).val(id);

            axios.get('/api/v1/expenses/bill/?' + $.param({
                bill_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const bill = response.data;
                    $('#name', $form).val(bill.name);
                    $('#company', $form).val(bill.company);
                    $('#bill_type_id', $form).val(bill.bill_type_id);
                    $('#day_of_month_due', $form).val(bill.day_of_month_due);
                    $('#autopay_flag', $form).iCheck(bill.autopay_flag ? "check" : "uncheck");
                    $('#login_id', $form).val(bill.login_id);
                    $('#website', $form).val(bill.website);
                    $('#username', $form).val(bill.username);
                    $('#date_opened', $form).val(bill.date_opened);
                    $('#date_closed', $form).val(!bill.date_closed ? '' : bill.date_closed);
                    $modal.find('.modal-title').text('Edit Bill');
                })
                .catch(error => {
                    console.error(error.response.data.message)
                    $modal.find('.modal-title').text('New Bill')
                })
        }
        else {
            $modal.find('.modal-title').text('New Bill')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddBill').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this bill from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/expenses/bill/', {
                data: {
                    bill_id: $('#bill_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.autopay_flag = !!payload.autopay_flag || false;

            axios.post('/api/v1/expenses/bill/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
