import $ from "jquery";
import axios from 'axios'
import JRSPay from './JRSPay';
import Chart from 'chart.js/auto'
import Finance from './finance';

export default function(){
    let apxRetirementPerformance;
    let fnc = new Finance();

    // const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    let inputs = {
        current_year: JRSPay.fyear() - 1,
        birth_year: 0,
        life_expectancy: 0,
        retirement_salary: 0,
        retirement_age: 0,
        inflation_projection: 0,
        avg_contribution: 0,
        overall_roi: NaN,
        overall_ror: NaN,
        standard_ror: 0.08,
        optimistic_ror: 0.10,
        pessimistic_ror: 0.05,
        balance: 0,
        ss_benefit: 0,
        ss_adjustment: 0,
        past_performance: {
            contributions: [],
            balance: []
        }
    };
    let outputs = {
        retire_year: NaN,
        retirement_salary_fv: NaN,
        total_savings_requirement: NaN,
        balance_projected: NaN,
        retirement_age_projected: NaN,
        retirement_salary_projected: NaN,
        yearly_savings_requirement: NaN,
        yearly_savings_requirement_std: NaN,
        contribution_requirement: NaN,
        future_performance: {
            contributions: [],
            balance: [],
            balance_optimistic: [],
            balance_pessimistic: []
        }
    };

    const loadRetirementChart = function() {
        axios.get('/api/v1/retirement/performance.php?' + $.param({
            start_year: inputs.current_year - 6,
            end_year: inputs.current_year,
        }))
            .then(response => {
                response = response.data;
                console.log(response);

                const $thead = $('#tblRetirement thead');
                const $tbody = $('#tblRetirement tbody');
                $thead.find('th:not(.row-title)').remove();
                $tbody.find('td:not(.row-title)').remove();
                inputs.past_performance.contributions = [];
                inputs.past_performance.balance = [];

                for (let i = 0; i < response.data.length; i++) {
                    inputs.past_performance.contributions.push({x: response.data[i].fyear, y: response.data[i].total_net_contributions})
                    inputs.past_performance.balance.push({x: response.data[i].fyear, y: response.data[i].balance})

                    $thead.find('tr').append($('<th>').attr('fyear', response.data[i].fyear).text(response.data[i].fyear).addClass('text-center'));
                    $tbody.find('tr.row-balance').append($('<td>').attr('fyear', response.data[i].fyear).text(formatMoney(response.data[i].balance)).addClass('text-center'));
                    $tbody.find('tr.row-net_contributions').append($('<td>').attr('fyear', response.data[i].fyear).text(formatMoney(response.data[i].net_contributions)).addClass('text-center'));
                    $tbody.find('tr.row-ror').append($('<td>').attr('fyear', response.data[i].fyear).text(formatPerc(response.data[i].ror)).addClass('text-center'));
                }

                let f = 0.5;
                let total_f = 0;
                let overall_roi = 0;
                let overall_ror = 0;
                let avg_contribution = 0;
                for (let i = response.data.length - 1; i > 0 && i > response.data.length - 4; i--) {
                    overall_roi += parseFloat(+response.data[i].roi || 0) * f;
                    overall_ror += parseFloat(+response.data[i].ror || 0) * f;
                    avg_contribution += parseFloat(+response.data[i].net_contributions || 0) * f;
                    total_f += f;
                    f /= 2;
                }

                inputs.balance = +response.data[response.data.length - 1].balance;
                inputs.overall_roi = overall_roi / total_f;
                inputs.overall_ror = overall_ror / total_f;
                inputs.avg_contribution = Math.round(avg_contribution / total_f);
                console.log(inputs);

                paintInputs();
                renderRetirementCalcs();

            })
            .catch(error => {
                console.error(error.response.data.message);
                alert('Something went wrong: ' + error.response.data.message)
            })
    };

    const loadRetirementGoals = function() {
        // console.log(arguments);

        return axios.get('/api/v1/retirement/goals.php?user_id=' + JRSPay.current_user_id())
            .then(response => {
                response = response.data;
                console.log(response);

                const data = response.data;
                inputs.birth_year = +data.birth_year;
                inputs.retirement_salary = data.retirement_salary * 1;
                inputs.retirement_age = data.retirement_age;
                inputs.life_expectancy = +data.life_expectancy;
                inputs.inflation_projection = +data.inflation_projection;
                inputs.ss_benefit = +data.ss_benefit;
                inputs.ss_adjustment = +data.ss_adjustment;
                inputs.standard_ror = +data.standard_ror;
                inputs.optimistic_ror = +data.optimistic_ror;
                inputs.pessimistic_ror = +data.pessimistic_ror;

                paintInputs();
                renderRetirementCalcs();
            })

    };

    const paintInputs = function() {
        // update form
        $('[name=retirement_salary]').val(inputs.retirement_salary);
        $('[name=retirement_age]').val(inputs.retirement_age);
        $('[name=avg_contribution]').val(inputs.avg_contribution);
        $('[name=life_expectancy]').val(inputs.life_expectancy);
        $('[name=ss_benefit]').val(inputs.ss_benefit);
        $('[name=ss_adjustment]').val(inputs.ss_adjustment);
        $('[name=inflation_projection]').val((100 * inputs.inflation_projection).toFixed(2));
        $('[name=standard_ror]').val((100 * inputs.standard_ror).toFixed(2));
        $('[name=optimistic_ror]').val((100 * inputs.optimistic_ror).toFixed(2));
        $('[name=pessimistic_ror]').val((100 * inputs.pessimistic_ror).toFixed(2));

        // update ui
        $('.retirement_salary').text(formatMoney(inputs.retirement_salary, 0));
        $('.avg_contribution').text(formatMoney(inputs.avg_contribution, 0));
        $('.retirement_age').text(inputs.retirement_age);
        $('.standard_ror').text(formatPerc(inputs.standard_ror));
        $('.optimistic_ror').text(formatPerc(inputs.optimistic_ror));
        $('.pessimistic_ror').text(formatPerc(inputs.pessimistic_ror));
        $('.inflation_projection').text(formatPerc(inputs.inflation_projection));
        $('.current_year').text(inputs.current_year);
        $('.balance').text(formatMoney(inputs.balance, 0));
        $('.overall_ror').text(formatPerc(inputs.overall_ror));
        $('.overall_roi').text(formatPerc(inputs.overall_roi));

        apxRetirementPerformance.data.datasets[0].data = inputs.past_performance.contributions;
        apxRetirementPerformance.data.datasets[1].data = inputs.past_performance.balance;
        apxRetirementPerformance.update();
    }

    const renderRetirementCalcs = function() {
        outputs.retire_year = inputs.retirement_age + inputs.birth_year;
        const years_til_retirement = outputs.retire_year - inputs.current_year;
        const retirement_length = inputs.life_expectancy - inputs.retirement_age;
        const standard_ror = inputs.standard_ror;
        const inflation = inputs.inflation_projection;
        const ss_benefit = inputs.ss_benefit * inputs.ss_adjustment;

        outputs.retirement_salary_fv = fnc.fv(inflation, years_til_retirement, 0, -1 * inputs.retirement_salary);
        outputs.total_savings_requirement = (outputs.retirement_salary_fv - ss_benefit) * retirement_length;

        outputs.balance_projected = fnc.fv(
            standard_ror / 26,
            years_til_retirement * 26,
            -1 * inputs.avg_contribution / 26,
            -1 * inputs.balance
        );
        if (outputs.balance_projected < 0) {
            outputs.balance_projected = NaN;
        }

        outputs.retirement_age_projected = Math.ceil(fnc.periods(
            standard_ror / 26,
            -1 * inputs.avg_contribution / 26,
            -1 * inputs.balance,
            outputs.total_savings_requirement
        ) / 26) + inputs.current_year - inputs.birth_year;
        outputs.retirement_salary_projected = outputs.balance_projected / retirement_length + ss_benefit;
        outputs.retirement_salary_projected_pv = fnc.pv(inflation, years_til_retirement, 0, -1 * outputs.retirement_salary_projected);
        outputs.yearly_savings_requirement = -1 * fnc.pmt(
            standard_ror / 26,
            years_til_retirement * 26,
            -1 * inputs.balance,
            outputs.total_savings_requirement
        ) * 26;

        outputs.yearly_savings_requirement_std = -1 * fnc.pmt(
            standard_ror / 26,
            years_til_retirement * 26,
            -1 * inputs.balance,
            outputs.total_savings_requirement
        ) * 26;

        outputs.contribution_requirement = outputs.yearly_savings_requirement_std * years_til_retirement;

        // add projections
        const last_i = inputs.past_performance.contributions.length - 1
        outputs.future_performance.contributions = [];
        outputs.future_performance.balance = [];
        outputs.future_performance.balance_pessimistic = [];
        outputs.future_performance.balance_optimistic = [];

        if (last_i > 0) {
            const projection_start = inputs.past_performance.contributions[last_i].x;
            let running_contributions = inputs.past_performance.contributions[last_i].y;
            let running_balance = inputs.past_performance.balance[last_i].y;
            let running_balance_pessimistic = inputs.past_performance.balance[last_i].y;
            let running_balance_optimistic = inputs.past_performance.balance[last_i].y;

            for (let yr = projection_start + 1; yr <= outputs.retire_year; yr++) {
                running_contributions += inputs.avg_contribution;
                running_balance = fnc.fv(
                    standard_ror / 26,
                    26,
                    -1 * inputs.avg_contribution / 26,
                    -1 * running_balance
                );

                running_balance_pessimistic = fnc.fv(
                    inputs.pessimistic_ror / 26,
                    26,
                    -1 * inputs.avg_contribution / 26,
                    -1 * running_balance_pessimistic
                );

                running_balance_optimistic = fnc.fv(
                    inputs.optimistic_ror / 26,
                    26,
                    -1 * inputs.avg_contribution / 26,
                    -1 * running_balance_optimistic
                );

                outputs.future_performance.contributions.push({x: yr, y: running_contributions})
                outputs.future_performance.balance.push({x: yr, y: running_balance})
                outputs.future_performance.balance_pessimistic.push({x: yr, y: running_balance_pessimistic})
                outputs.future_performance.balance_optimistic.push({x: yr, y: running_balance_optimistic})
            }
        }

        console.log(outputs);

        paintOutputs();
    }

    const paintOutputs = function() {
        apxRetirementPerformance.data.datasets[2].data = outputs.future_performance.contributions;
        apxRetirementPerformance.data.datasets[3].data = outputs.future_performance.balance;
        apxRetirementPerformance.data.datasets[4].data = outputs.future_performance.balance_optimistic;
        apxRetirementPerformance.data.datasets[5].data = outputs.future_performance.balance_pessimistic;
        apxRetirementPerformance.update();

        const is_saving_success = inputs.avg_contribution >= outputs.yearly_savings_requirement_std;
        const is_saving_warning = inputs.avg_contribution >= (outputs.yearly_savings_requirement_std * 0.5);
        $('#retirement_output_wrapper .savings-callout').hide();

        if (outputs.future_performance.balance.length > 0) {
            if (is_saving_success) {
                $('#retirement_output_wrapper .callout-success').fadeIn();
            } else if (is_saving_warning) {
                $('#retirement_output_wrapper .callout-warning').fadeIn();
            } else {
                $('#retirement_output_wrapper .callout-danger').fadeIn();
            }
        }

        const is_nan = function(v) {
            return isNaN(v) || v === Infinity;
        }

        $('.balance_projected').text(formatMoney(outputs.balance_projected, 0));
        $('.retirement_salary_projected_pv').text(formatMoney(outputs.retirement_salary_projected_pv, 0));
        $('.retirement_age_projected').text(is_nan(outputs.retirement_age_projected) ? '--' : outputs.retirement_age_projected);
        $('.contribution_requirement').text(formatMoney(outputs.contribution_requirement, 0));

        const saving_diff = (outputs.yearly_savings_requirement_std - inputs.avg_contribution ) / inputs.avg_contribution;
        $('.yearly_savings_requirement_std').text(formatMoney(outputs.yearly_savings_requirement_std, 0));
        $('.yearly_savings_requirement_std').parent().find('small').text((saving_diff >= 0 ? '+' : '') + formatPerc(saving_diff, 0));

        const timeline_progress = (inputs.current_year - inputs.birth_year - 18) / (outputs.retire_year - inputs.birth_year - 18);
        $('.retire_year').text(outputs.retire_year);
        $('.progress-bar-timeline').css({
            width: formatPerc(timeline_progress)
        });

        const balance_progress = inputs.balance / outputs.contribution_requirement;
        $('.progress-bar-balance').css({
            width: formatPerc(balance_progress)
        }).removeClass('progress-bar-warning progress-bar-success')
            .addClass(balance_progress > timeline_progress ? 'progress-bar-success' : 'progress-bar-warning');

        if (outputs.retirement_salary_projected_pv >= inputs.retirement_salary) {
            let salary_progress = inputs.retirement_salary / outputs.retirement_salary_projected_pv;
            $('.progress-bar-salary').css({
                width: formatPerc(salary_progress)
            })
            $('.progress-bar-salary-proj').css({
                width: '100%'
            }).removeClass('progress-bar-warning progress-bar-success')
                .addClass('progress-bar-success')
        }
        else {
            let salary_progress = outputs.retirement_salary_projected_pv / inputs.retirement_salary;
            $('.progress-bar-salary').css({
                width: '100%'
            })
            $('.progress-bar-salary-proj').css({
                width: formatPerc(salary_progress)
            }).removeClass('progress-bar-warning progress-bar-success')
                .addClass(salary_progress < 0.90 ? 'progress-bar-warning' : 'progress-bar-success')
        }

    }

    const initChart = function() {
        apxRetirementPerformance = new Chart(document.getElementById('chtRetirementPerformance'), {
            type: 'scatter',
            data: {
                datasets: [{
                    label: 'Contributions',
                    data: [],
                    showLine: true,
                    tension: 0,
                }, {
                    label: 'Balance',
                    data: [],
                    showLine: true,
                    tension: 0.3,
                }, {
                    label: 'Projected Contributions',
                    data: outputs.future_performance.contributions,
                    showLine: true,
                    pointStyle: false,
                    tension: 0,
                }, {
                    label: 'Projected Balance',
                    data: outputs.future_performance.balance,
                    showLine: true,
                    pointStyle: false,
                    tension: 0.3,
                }, {
                    label: 'Optimistic Projection',
                    data: outputs.future_performance.balance_optimistic,
                    showLine: true,
                    pointStyle: false,
                    borderDash: [10, 10],
                    fill: '+1',
                    tension: 0.3,
                }, {
                    label: 'Pessimistic Projection',
                    data: outputs.future_performance.balance_pessimistic,
                    showLine: true,
                    pointStyle: false,
                    borderDash: [10, 10],
                    tension: 0.3,
                }],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                    x: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return String(value);
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y, 0);
                            }
                        }
                    }
                }
            }
        });
    }

    const saveGoals = function() {
        let payload = {...inputs};
        payload.user_id = JRSPay.current_user_id();
        payload.past_performance = undefined;
        return axios.post('/api/v1/retirement/goals.php', payload);
    }

    const init = function() {
        const $modal = $('#mdlEditAssumptions')
        const $form = $('#frmEditAssumptions')

        $modal.modal({
            show: false
        })

        initChart();
        paintInputs();
        paintOutputs();
        loadRetirementGoals();
        loadRetirementChart();

        $('.btnEditGoals').click(function(){
            $('#divGoalsEdit').toggle(400);
        });

        $('[name=retirement_salary]').change(function(){
            inputs.retirement_salary = +this.value
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=retirement_age]').change(function(){
            inputs.retirement_age = +this.value
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=life_expectancy]').change(function(){
            inputs.life_expectancy = +this.value
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=ss_benefit]').change(function(){
            inputs.ss_benefit = +this.value
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=inflation_projection]').change(function(){
            inputs.inflation_projection = +this.value / 100
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=ss_adjustment]').change(function(){
            inputs.ss_adjustment = +this.value
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=standard_ror]').change(function(){
            inputs.standard_ror = +this.value / 100
            if (inputs.standard_ror > inputs.optimistic_ror) {
                inputs.standard_ror = inputs.optimistic_ror
            }
            if (inputs.standard_ror < inputs.pessimistic_ror) {
                inputs.standard_ror = inputs.pessimistic_ror
            }
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=optimistic_ror]').change(function(){
            inputs.optimistic_ror = +this.value / 100
            if (inputs.optimistic_ror < inputs.standard_ror) {
                inputs.optimistic_ror = inputs.standard_ror
            }
            paintInputs();
            renderRetirementCalcs();
        });

        $('[name=pessimistic_ror]').change(function(){
            inputs.pessimistic_ror = +this.value / 100
            if (inputs.pessimistic_ror > inputs.standard_ror) {
                inputs.pessimistic_ror = inputs.standard_ror
            }
            paintInputs();
            renderRetirementCalcs();
        });

        $('.btnEditAssumptions').click(function(){
            $modal.modal('show')
        });

        $('.btnSaveGoals').click(function(evt) {
            $(this).addClass('disabled')

            saveGoals()
                .then(response => {
                    alert("Saved!");
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
                .finally(() => {
                    $(this).removeClass('disabled')
                })
        });

        $('.btnResetGoals').click(function(evt) {
            loadRetirementGoals()
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            saveGoals()
                .then(response => {
                    alert("Saved!");
                    $('#mdlEditAssumptions').modal('hide')
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });
    };

    init();

};
