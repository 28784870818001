import $ from "jquery";
import JRSPay from '../JRSPay';
import Chart from 'chart.js/auto'
import axios from 'axios';

export default function(){

    let mrsNotePerformance;
    const $form = $('#frmNoteInput');
    const $table = $('#tblNotes');
    const $modal = $('#modalNote');

    let $dataTable;

    const loadFormInputs = function() {
        $('#fyear', $form).append($('#sidebar-fyear').find('option').clone())

        axios.get('/api/v1/settings/user/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const user of response.data) {
                    $('#owner_login_id', $form).append(
                        $('<option>').attr('value', user.id).text(user.name)
                    );
                }
            });

        axios.get('/api/v1/expenses/bill/list.php?bill_type_id=6')
            .then(response => {
                response = response.data;
                console.log(response);

                $('#bill_id', $form).append(
                    $('<option>').attr('value', 0).text('Not Applicable')
                );

                let $active = $('<optgroup>').attr('label', 'Active');
                let $inactive = $('<optgroup>').attr('label', 'Inactive');

                for (const bill of response.data) {
                    let $opt = $('<option>').attr('value', bill.id).text(bill.name)

                    if (bill.is_active) {
                        $opt.appendTo($active);
                    } else {
                        $opt.appendTo($inactive);
                    }
                }

                $('#bill_id', $form).append([
                    $active,
                    $inactive
                ])
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No notes in the system yet.'
            },
            columns: [{
                title: 'Name',
                data: 'name',
                width: '17%',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Principle',
                data: 'principle',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Status',
                data: 'status',
            }, {
                title: 'Owner',
                data: 'owner',
            }, {
                title: 'Interest Rate',
                data: 'interest_rate',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatPerc(data);
                    }
                    return data;
                }
            }, {
                title: 'Principle Payable',
                data: 'principle_payable',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data) + ' <button class="btn btn-default btn-xs btnEditBalance">Edit <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Balance',
                data: 'balance',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }, {
                title: 'Payments',
                data: 'payments',
                class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/liabilities/note-payable/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })

        loadNoteCharts();
    };

    const loadFormData = function(id) {
        $('#note_payable_id', $form).val(0);
        $('#name', $form).val('');
        $('#account_opened', $form).val('');
        $('#account_closed', $form).val('');
        $('#is_mortgage', $form).iCheck("uncheck");
        $('#bill_id', $form).val(0)
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#note_payable_id', $form).val(id);

            axios.get('/api/v1/liabilities/note-payable/?' + $.param({
                note_payable_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const ar = response.data;
                    $('#name', $form).val(ar.name);
                    $('#account_opened', $form).val(ar.account_opened);
                    $('#account_closed', $form).val(!ar.account_closed ? '' : ar.account_closed);
                    $('#owner_login_id', $form).val(ar.owner_login_id);
                    $('#principle', $form).val(ar.principle.toFixed(2));
                    $('#interest_rate', $form).val(ar.interest_rate.toFixed(4));
                    $('#bill_id', $form).val(ar.bill_id);
                    $('#is_mortgage', $form).iCheck(ar.is_mortgage ? "check" : "uncheck");
                    $modal.find('.modal-title').text('Edit Note Payable');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Note Payable')
                })
        }
        else {
            $modal.find('.modal-title').text('New Note Payable')
        }
    }

    const loadNoteCharts = function(note_payable_id = 0, name = '') {
        const $metrics_table = $('#metrics-table');
        const $note_profile_wrapper = $('#note_profile_wrapper');
        $('tbody tr:not(:first-child) td.value:not(.external)', $metrics_table).attr('contenteditable', (note_payable_id > 0));
        $('.note_payable_id', $note_profile_wrapper).text(note_payable_id);

        if (note_payable_id > 0) {
            $('#spnChartTitle').text(' - ' + name);
            $('#btnBackToOverall').show();
        } else {
            $('#spnChartTitle').text('');
            $('#btnBackToOverall').hide();
        }
        const render_metric = function(current_value, prev_value, $metric) {
            $('.value', $metric).text(formatMoney(current_value));
            $('.value', $metric).data('value_org', current_value);
            let new_class = get_class(current_value - prev_value)
            let new_caret = get_caret(current_value - prev_value)
            let incr_perc = get_incr_perc(current_value, prev_value);
            $('.percentage span', $metric).text(formatPerc(incr_perc));
            $('.percentage', $metric).attr('class', 'percentage ' + new_class);
            $('.percentage i', $metric).attr('class', 'fa ' + new_caret);
        };
        const render_footer_metric = function(current_value, prev_value, $metric, is_perc) {
            let new_class = get_class(current_value - prev_value)
            let new_caret = get_caret(current_value - prev_value)
            let incr_perc = get_incr_perc(current_value, prev_value);
            $('.description-header', $metric).text(is_perc ? formatPerc(current_value) : formatMoney(current_value));
            $('.description-percentage .percentage', $metric).text(formatPerc(incr_perc));
            $('.description-percentage', $metric).removeClass(['text-muted', 'text-green', 'text-red']).addClass(new_class);
            $('.description-percentage i', $metric).attr('class', 'fa ' + new_caret);
        };

        axios.get('/api/v1/liabilities/note-payable/performance.php?' + $.param({
            note_payable_id: note_payable_id,
            fyear: JRSPay.fyear()
        }))
            .then(response => {
                response = response.data.data;
                console.log(response);

                mrsNotePerformance.data.labels = response.data.map(d => d.fyear)
                mrsNotePerformance.data.datasets[0].data = response.data.map(d => d.principle_payable);
                mrsNotePerformance.data.datasets[1].data = response.data.map(d => d.interest_payable);
                mrsNotePerformance.update();

                const metrics = response.metrics;
                $('#metric-name .value').text(response.metrics.name || 'Overall');

                const total_payable = parseFloat(metrics.principle_payable) + parseFloat(metrics.interest_payable);
                const total_payable_prev = parseFloat(metrics.principle_payable_prev) + parseFloat(metrics.interest_payable_prev);

                const payment_eff = (metrics.payments === 0 ? null : metrics.principle_paid / metrics.payments);
                const payment_eff_prev = (metrics.payments_prev === 0 ? null : metrics.principle_paid_prev / metrics.payments_prev);

                render_metric(metrics.principle_paid, metrics.principle_paid_prev, $('#metric-principle_paid'));
                render_metric(metrics.payments, metrics.payments_prev, $('#metric-payments'));
                render_metric(metrics.interest_paid, metrics.interest_paid_prev, $('#metric-interest_paid'));
                render_metric(metrics.interest_accrued, metrics.interest_accrued_prev, $('#metric-interest_accrued'));
                render_metric(metrics.disbursements, metrics.disbursements_prev, $('#metric-disbursements'));
                render_metric(metrics.interest_cap, metrics.interest_cap_prev, $('#metric-interest_cap'));

                // footer
                render_footer_metric(total_payable, total_payable_prev, $('#footer-total_payable'));
                render_footer_metric(metrics.principle_payable, metrics.principle_payable_prev, $('#footer-principle'));
                render_footer_metric(metrics.interest_payable, metrics.interest_payable_prev, $('#footer-interest'));
                render_footer_metric(payment_eff, payment_eff_prev, $('#footer-payment_eff'), 1);
            })
            .catch(error => {
                console.error(error.response.data.message);
            })
    };

    const init = function() {

        $dataTable = initDataTable();

        $modal.modal({
            show: false
        });

        mrsNotePerformance = new Chart(document.getElementById('chtNotesPerformance'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Principle',
                    data: [],
                    showLine: true,
                    fill: true,
                    tension: 0.3,
                }, {
                    label: 'Interest',
                    data: [],
                    showLine: true,
                    fill: true,
                    tension: 0.3,
                }],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        stacked: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });

        loadFormInputs();
        getTableData();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $dataTable.on('click', 'tbody .btnEditBalance', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadNoteCharts(data.id, data.name)
        });

        $('#btnAddNote').click(function(){
            loadFormData(0);
        });

        $('#btnBackToOverall').click(function() {
            loadNoteCharts(0)
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this note from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/liabilities/note-payable.php', {
                data: {
                    note_payable_id: $('[name=note_payable_id]', $form).val()
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.principle = parseMoney(payload.principle);
            payload.interest_rate = parseFloat(payload.interest_rate);
            payload.is_mortgage = !!payload.is_mortgage || false

            axios.post('/api/v1/liabilities/note-payable/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

        $('#metrics-table').on('keydown focusout', 'td[contenteditable=true]', function (e) {
            const $this = $(e.currentTarget);
            const working = $this.data('working');
            const note_payable_id = +$('#note_profile_wrapper .note_payable_id').text() || 0;
            if (note_payable_id === 0) {
                return;
            }
            if (working) {
                return;
            }

            if ((+e.keyCode === 13 || e.type === 'focusout')) {
                const type = $this.parent().attr('id').split('-')[1];
                const value = parseMoney($this.text());
                const value_org = parseMoney($this.data('value_org') || '');
                // console.log(type);
                // console.log(value_org);
                // console.log(value);

                if (value !== value_org) {
                    $this.data('working', true);
                    e.preventDefault();
                    if (e.type !== 'blur') {
                        console.log(type);
                        $this.blur();
                    }

                    axios.patch('/api/v1/liabilities/note-payable/', {
                        action: 'set-field',
                        fyear: JRSPay.fyear(),
                        field: type,
                        note_payable_id: note_payable_id,
                        value: value
                    })
                        .then(response => {
                            response = response.data;
                            console.log(response);
                            $this.data('value_org', value);
                            $this.text(formatMoney(value));

                            const name = $('#spnChartTitle').text().substring(3);
                            loadNoteCharts(note_payable_id, name);
                        })
                        .catch(error => {
                            console.error(error.response.data.message);
                            alert('Something went wrong: ' + error.response.data.message)
                        })
                        .finally(() => {
                            $this.data('working', false);
                        })
                } else {
                    $this.text(formatMoney(value_org));
                }
            }
        });
    };

    init();

};
