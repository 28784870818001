import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default function(){
    let chtTaxRates;
    let gross_salary = {}, total_assets = {}, net_worth = {}, taxes = {}, real_estate = {}

    function initCharts() {
        chtTaxRates = new Chart(document.getElementById('chtTaxRates'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatPerc(value, 0);
                            }
                        }
                    },
                    y_right: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        // grid line settings
                        grid: {
                            drawOnChartArea: false,
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                if (context.dataset.yAxisID === 'y_right') {
                                    return label + ': ' + formatMoney(context.parsed.y, 0);
                                }
                                return label + ': ' + formatPerc(context.parsed.y, 2);
                            }
                        }
                    }
                }
            }
        });
    }

    function loadBackendData() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        let promises = [];

        for (let y = init_year; y <= this_year; y++) {
            promises.push(
                axios.get('/api/v1/balance-sheet/?' + $.param({
                    section: 'all',
                    fyear: y,
                }))
            )
        }

        return Promise.all(promises)
            .then(responses => {

                for (const response of responses) {
                    let uses = response.data.data.cash_flows.uses
                    let sources = response.data.data.cash_flows.sources
                    let fyear = response.data.data.fyear

                    // add to main line chart
                    gross_salary[fyear] = sources.gross_salary + sources.llc_distributions + sources.freelance + sources.interest_dividends
                    total_assets[fyear] = response.data.data.assets.total
                    net_worth[fyear] = response.data.data.net_worth
                    real_estate[fyear] = response.data.data.assets.noncurrent_assets.real_estate.total
                    taxes[fyear] = {
                        income_taxes: uses.income_taxes,
                        other_taxes: uses.other_taxes,
                        total_taxes: uses.income_taxes + uses.other_taxes,
                    }
                }

                $('[name=taxes_paid]').on('ifToggled', () => loadTaxRateChart())
                $('[name=taxable_amount]').on('ifToggled', () => loadTaxRateChart())

                loadTaxRateChart()
            })
    }

    function loadTaxRateChart() {
        const taxes_paid_type = $('[name=taxes_paid]:checked').val()
        const balance_type = $('[name=taxable_amount]:checked').val()

        const taxes_paid_name = $('[name="taxes_paid"]:checked').closest('label').text().trim()
        const balance_name = $('[name="taxable_amount"]:checked').closest('label').text().trim()

        let tax_rates = []

        // taxes paid
        if (taxes_paid_type === 'income') {
            tax_rates = Object.values(taxes).map(v => [v.income_taxes, 0])
        } else if (taxes_paid_type === 'property') {
            tax_rates = Object.values(taxes).map(v => [v.other_taxes, 0])
        } else {
            tax_rates = Object.values(taxes).map(v => [v.total_taxes, 0])
        }

        // balance
        if (balance_type === 'salary') {
            tax_rates = tax_rates.map((tr, i) => [tr[0], Object.values(gross_salary)[i]])
        } else if (balance_type === 'real_estate') {
            tax_rates = tax_rates.map((tr, i) => [tr[0], Object.values(real_estate)[i]])
        } else if (balance_type === 'assets') {
            tax_rates = tax_rates.map((tr, i) => [tr[0], Object.values(total_assets)[i]])
        } else {
            tax_rates = tax_rates.map((tr, i) => [tr[0], Object.values(net_worth)[i]])
        }

        // update line chart
        chtTaxRates.data.labels = Object.keys(net_worth)
        chtTaxRates.data.datasets = [{
            label: 'Tax Rate',
            data: tax_rates.map(tr => tr[0] / tr[1]),
            yAxisID: 'y',
        }, {
            label: taxes_paid_name,
            data: tax_rates.map(tr => tr[0]),
            borderDash: [10,5],
            yAxisID: 'y_right',
        }, {
            label: balance_name,
            data: tax_rates.map(tr => tr[1]),
            borderDash: [10,5],
            yAxisID: 'y_right',
        }];

        chtTaxRates.update()
    }

    initCharts()

    // start
    const $mdl = $('#mdlLoading');
    $mdl.modal({
        show: true,
        backdrop: 'static',
    })

    let message_count = 0;
    let messages = JRSPay.loading_messages();
    messages.shuffle();

    const set_new_title = function() {
        $('.modal-title', $mdl)
            .hide()
            .text(messages[message_count] + '...')
            .fadeIn(400)
        message_count++
    }

    set_new_title();
    let modal_timer = setInterval(set_new_title, 4000)

    loadBackendData().then(() => {
        modal_timer = undefined
        $mdl.modal('hide');
    })
}

