import $ from "jquery";
import JRSPay from '../JRSPay';
import {DateTime} from 'luxon';
import axios from 'axios'
import '../bootstrap3-typeahead'

export default function(){

    const $form = $('#frmGigInput');
    const $table = $('#tblGig');
    const $modal = $('#modalGig');
    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/settings/venue/list.php')
            .then(response => {
                response = response.data;
                console.log(response);
                $("#venue").typeahead({
                    source: response.data
                });
            });

        axios.get('/api/v1/settings/client/list.php')
            .then(response => {
                response = response.data;
                console.log(response);

                let $active = $('<optgroup>').attr('label', 'Active');
                let $inactive = $('<optgroup>').attr('label', 'Inactive');

                for (const client of response.data) {
                    let $opt = $('<option>').attr('value', client.id).text(client.name + ' (' + client.office + ')');

                    if (client.is_active) {
                        $opt.appendTo($active);
                    } else {
                        $opt.appendTo($inactive);
                    }
                }

                $('#client_id', $form).append([
                    $active,
                    $inactive
                ])
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No gigs for this year.'
            },
            columns: [{
                title: 'Gig Date',
                data: 'gig_date',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + DateTime.fromSQL(data).toLocaleString() + ' <i class="fa fa-edit"></i></button>';

                    }
                    return data;
                }
            }, {
                title: 'Office',
                data: 'office'
            }, {
                title: 'Client',
                data: 'client',
            }, {
                title: 'Venue',
                data: 'venue',
            }, {
                title: 'Gross Pay',
                data: 'gross_pay',
                class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return formatMoney(data);
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/revenue/gig/list.php?fyear=' + JRSPay.fyear())
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#gig_id', $form).val(0);
        $('#gig_date', $form).val('');
        $('#gross_pay', $form).val('');
        $('#client_id', $form).val(0);
        $('#venue', $form).val('');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#gig_id', $form).val(id);

            axios.get('/api/v1/revenue/gig/?' + $.param({
                gig_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const gig = response.data;
                    $('#gig_date', $form).val(gig.gig_date);
                    $('#gross_pay', $form).val(gig.gross_pay.toFixed(2));
                    $('#client_id', $form).val(gig.client_id);
                    $('#venue', $form).val(gig.venue);
                    $modal.find('.modal-title').text('Edit Gig');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New Gig')
                })
        }
        else {
            $modal.find('.modal-title').text('New Gig')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddGig').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this gig from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/revenue/gig/', {
                data: {
                    gig_id: $('#gig_id', $form).val()
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();

            let payload = $form.serializeObject();
            payload.gross_pay = parseMoney(payload.gross_pay);

            axios.post('/api/v1/revenue/gig/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
