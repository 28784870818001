import $ from "jquery";
import {DateTime} from 'luxon';
import axios from 'axios'

export default function(){

    const $form = $('#frmUserInput');
    const $table = $('#tblUsers');
    const $modal = $('#modalUser');
    let $dataTable;

    const loadFormInputs = function() {

        axios.get('/api/v1/settings/user/account-status.php')
            .then(response => {
                response = response.data;
                console.log(response);

                for (const status of response.data) {
                    $('#account_status_id', $form).append(
                        $('<option>').attr('value', status.id).text(status.name)
                    )
                }
            });

    };

    const initDataTable = function(){
        return $table.dataTable({
            language: {
                zeroRecords: 'No users in the system yet.'
            },
            columns: [{
                title: 'Name',
                data: 'name',
                // class: 'text-right',
                render: function (data, type) {
                    if (type === 'display') {
                        return '<button class="btn-link btnLoadEditForm" >' + data + ' <i class="fa fa-edit"></i></button>';
                    }
                    return data;
                }
            }, {
                title: 'Title',
                data: 'title',
                // class: 'text-right',
            }, {
                title: 'Status',
                data: 'account_status',
                // class: 'text-right',
            }, {
                title: 'Birthdate',
                data: 'birthdate',
                // class: 'text-right',
                render: function(data, type) {
                    if (type === 'display') {
                        return DateTime.fromSQL(data).toLocaleString();
                    }
                    return data;
                }
            }],
            data: []
        });
    };

    const getTableData = function() {
        $dataTable.api().clear().draw();

        axios.get('/api/v1/settings/user/list.php')
            .then(response => {
                response = response.data;
                console.log(response);
                $dataTable.api().rows.add(response.data).draw();
            })
    };

    const loadFormData = function(id) {
        $('#user_id', $form).val(0);
        $('#username', $form).val('');
        $('#name', $form).val('');
        $('#title', $form).val('');
        $('#email', $form).val('');
        $('#birthdate', $form).val('');
        $('#account_status_id', $form).val(1);
        $('#is_developer', $form).iCheck('uncheck');
        $('#has_gigs_enabled', $form).iCheck('uncheck');
        $modal.modal('show');
        $modal.find('.modal-title').text('Loading...')

        if (id > 0) {
            $('#user_id', $form).val(id);

            axios.get('/api/v1/settings/user/?' + $.param({
                user_id: id
            }))
                .then(response => {
                    response = response.data;
                    console.log(response);

                    const user = response.data;
                    $('#username', $form).val(user.username);
                    $('#name', $form).val(user.name);
                    $('#title', $form).val(user.title);
                    $('#email', $form).val(user.email);
                    $('#birthdate', $form).val(user.birthdate);
                    $('#account_status_id', $form).val(user.account_status_id);
                    $('#is_developer', $form).iCheck(user.is_developer ? 'check' : 'uncheck');
                    $('#has_gigs_enabled', $form).iCheck(user.has_gigs_enabled ? 'check' : 'uncheck');
                    $modal.find('.modal-title').text('Edit User');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    $modal.find('.modal-title').text('New User')
                })
        }
        else {
            $modal.find('.modal-title').text('New User')
        }
    }

    const init = function() {

        $dataTable = initDataTable();
        getTableData();

        $modal.modal({
            show: false
        });

        loadFormInputs();

        $dataTable.on('click', 'tbody .btnLoadEditForm', function() {
            let data = $dataTable.api().row($(this).closest('tr')[0]).data();
            loadFormData(data.id)
        });

        $('#btnAddUser').click(function(){
            loadFormData(0);
        });

        $('#btnFormDelete').click(function() {
            const c = confirm("Are you sure you want to remove this user from the system?")

            if (!c) {
                return;
            }

            axios.delete('/api/v1/settings/user/', {
                data: {
                    user_id: $('#user_id', $form).val(),
                }
            })
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })
        });

        $form.submit(function(evt) {
            evt.preventDefault();
            let payload = $form.serializeObject();
            payload.is_developer = !!payload.is_developer || false;
            payload.has_gigs_enabled = !!payload.has_gigs_enabled || false;

            axios.post('/api/v1/settings/user/', payload)
                .then(response => {
                    response = response.data;
                    console.log(response);

                    getTableData();
                    $modal.modal('hide');
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    alert('Something went wrong: ' + error.response.data.message)
                })

            return false;
        });

    };

    init();

};
