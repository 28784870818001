import $ from "jquery";
import JRSPay from '../JRSPay';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default function(){
    let chtAssetsLine, chtAssetsPie;
    let assets_data = {};
    let asset_types = [];

    const fn_is_equity = () => !$('#chkMetricToggle').prop('checked')

    function initPagination() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        const $ulYears = $('#ulYears');

        const current_year = function(){
            return +$('li.active', $ulYears).data('fyear');
        };

        // pagination
        $('li > a', $ulYears).click(function () {
            let year_clicked = $(this).parent().data('fyear');
            if (year_clicked === 'prev') {
                year_clicked = current_year() - 1;
            } else if (year_clicked === 'next') {
                year_clicked = current_year() + 1;
            }

            if (year_clicked < init_year || year_clicked > this_year) {
                return;
            }
            if (year_clicked === current_year()) {
                return;
            }

            $('li', $ulYears).removeClass('active');
            $('li[data-fyear=' + year_clicked + ']', $ulYears).addClass('active');
            loadPieCharts(year_clicked)
        });
    }

    function initCharts() {
        chtAssetsPie = new Chart(document.getElementById('chtAssetsPie'), {
            type: 'pie',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: context => ' ' + formatMoney(context.parsed),
                        },
                    },
                    title: {
                        display: true,
                        text: 'Asset Values by Type'
                    }
                }
            }
        });
        chtAssetsLine = new Chart(document.getElementById('chtAssetsLine'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                scales: {
                    y: {
                        // type: 'logarithmic',
                        title: {
                            display: true
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return formatMoney(value, 0);
                            }
                        }
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        intersect: false,
                        position: 'nearest',
                        itemSort: (a, b) => b.raw - a.raw,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                return label + ': ' + formatMoney(context.parsed.y);
                            }
                        }
                    }
                }
            }
        });
    }

    function loadPieCharts(fyear) {
        chtAssetsPie.data.labels = asset_types.map(t => t.name)
        // console.log(assets_data)
        // console.log(asset_types.map(t => assets_data[fyear]))
        chtAssetsPie.data.datasets = [{ data: asset_types.map(t => +(assets_data[fyear][t.name] || {}).asset_value || null) }]
        chtAssetsPie.update('none')
    }

    function loadLineCharts() {
        const is_equity = fn_is_equity()

        chtAssetsLine.options.scales.y.title.text = is_equity ? "Equity in Assets" : "Assets Value"

        chtAssetsLine.data.labels = Object.keys(assets_data)
        chtAssetsLine.data.datasets = asset_types.map(t => {
            return {
                label: t.name,
                data: []
            }
        });

        const assets = Object.values(assets_data)
        if (assets.length > 0) {
            for (let b of assets) {
                for (let bt of asset_types) {
                    let dataset = chtAssetsLine.data.datasets.find(d => d.label === bt.name)
                    if (dataset) {
                        if (!b[bt.name]) {
                            dataset.data.push(null)
                        }
                        else {
                            const value = +b[bt.name].asset_value
                            const balance = +b[bt.name].balance_owed
                            if (is_equity) {
                                dataset.data.push(value - balance)
                            } else {
                                dataset.data.push(value)
                            }
                        }
                    }
                }
            }
        }

        chtAssetsLine.update()

        const $tblRates = $('#tblRates tbody')
        $tblRates.empty()
        $tblRates.append(
            asset_types.map(t => {
                let tds = [];
                tds.push($('<th>').text(t.name))

                let payments = []
                let rates = [[], [], []]
                let changes = [[], [], []]
                const entries = Object.entries(assets_data)
                for (let i = entries.length - 1; i >= entries.length - 6 && i >= 0; i--) {
                    const count = payments.length
                    const e = entries[i][1]
                    const exists = !!e[t.name]
                    let value = null
                    if (exists) {
                        value = e[t.name].asset_value - (is_equity ? e[t.name].balance_owed : 0)
                    }
                    payments.push(value)

                    let change = payments[count - 1] - payments[count]
                    let rate = change / payments[count]
                    if (count > 0) {
                        if (count < 2) {
                            rates[0].push(rate)
                            changes[0].push(change)
                        }
                        if (count < 4) {
                            rates[1].push(rate)
                            changes[1].push(change)
                        }
                        if (count < 6) {
                            rates[2].push(rate)
                            changes[2].push(change)
                        }
                    }
                }

                for (let r of Object.entries(rates)) {
                    let c = changes[r[0]].mean()
                    r = r[1].mean()
                    tds.push($('<td class="text-right">').append($('<span>').addClass(r < 0 ? "text-danger" : "text-success").text((r > 0 ? '+' : '') + formatMoney(c, 0) + ' (' + (r > 0 ? '+' : '') + formatPerc(r) + ')')))
                }

                return $('<tr>').append(tds)
            })
        )
    }

    function loadAssetsData() {
        const this_year = JRSPay.fyear();
        const init_year = JRSPay.init_fyear();
        let promises = [];

        promises.push(
            axios.get('/api/v1/noncurrent-assets/fixed/type.php')
                .then(response => {
                    asset_types = response.data.data;
                })
        )

        for (let y = init_year; y <= this_year; y++) {
            promises.push(
                axios.get('/api/v1/noncurrent-assets/fixed/list.php?' + $.param({
                    fyear: y,
                }))
                    .then(response => {
                        return {
                            fyear: y,
                            data: response.data.data
                        }
                    })
            )
        }

        return Promise.all(promises)
            .then(responses => {

                for (const response of responses) {
                    if (!response) {
                        continue
                    }
                    let data = response.data;
                    let fyear = +response.fyear;

                    data = Object.groupBy(data, ({ fixed_asset_type }) => fixed_asset_type)
                    let grouped = {};

                    for (let [type, d] of Object.entries(data)) {
                        let grp = d.reduce((acc, curr) => {
                            acc.asset_value += curr.status === "Sold" ? 0 : curr.asset_value
                            acc.balance_owed += curr.status === "Sold" ? 0 : curr.balance_owed
                            return acc
                        }, {
                            asset_value: 0,
                            balance_owed: 0,
                        })
                        grouped[type] = grp;
                    }

                    // add to main line chart
                    assets_data[fyear] = grouped;
                }

                loadPieCharts(this_year)
                loadLineCharts()
            })
    }

    function init() {
        // toggle
        $('#chkMetricToggle').change(function() {
            loadLineCharts();
        })

        // start
        const $mdl = $('#mdlLoading');
        $mdl.modal({
            show: true,
            backdrop: 'static',
        })

        let message_count = 0;
        let messages = JRSPay.loading_messages();
        messages.shuffle();

        const set_new_title = function() {
            $('.modal-title', $mdl)
                .hide()
                .text(messages[message_count] + '...')
                .fadeIn(400)
            message_count++
        }

        set_new_title();
        let modal_timer = setInterval(set_new_title, 4000)

        loadAssetsData().then(() => {
            modal_timer = undefined
            $mdl.modal('hide');
        })
    }

    initPagination()
    initCharts()
    init()

}

