var $ = require('jquery');
window.jQuery = $;
require('icheck');
require('bootstrap');
require('jquery-slimscroll')
require('bootstrap4-toggle');
import 'datatables';
import 'datatables.net-bs'

import './_common';
import AdminLTE from './AdminLTE/AdminLTE';

import Login from './login'
import Home from './home'
import Profile from './profile'
import Retirement from './retirement'
import BalanceSheet from './balance-sheet'
// current assets
import AccountsReceivable from './current-assets/ar'
import AccountsCash from './current-assets/cash'
// debt
import AccountsPayable from './liabilities/ap'
import NotesPayable from './liabilities/notes'
// expenses
import BillPayChecklist from './expenses/bill-pay-checklist'
import BillSetup from './expenses/bills'
import ItemizedExpenses from './expenses/itemized'
import WorkExpenses from './expenses/work'
import TaxPayments from './expenses/tax-payments'
// non-current
import RealEstate from './noncurrent-assets/real-estate'
import FixedAssets from './noncurrent-assets/fixed'
import Investments from './noncurrent-assets/investments'
// revenue
import Revenue_Gigs from './revenue/gigs'
import Revenue_K1 from './revenue/k1'
import Revenue_Payouts from './revenue/payouts'
import Revenue_W2 from './revenue/w2'
import Revenue_Freelance from './revenue/freelance'
import Revenue_TaxRefunds from './revenue/tax-refunds'
import Revenue_Misc from './revenue/misc'
// settings
import Settings_Clients from './settings/clients'
import Settings_Offices from './settings/offices'
import Settings_Users from './settings/users'
// analytics
import Analytics_CashFlows from './analytics/cash-flows'
import Analytics_Bills from './analytics/bills'
import Analytics_Assets from './analytics/assets'
import Analytics_Taxes from './analytics/taxes'
import Analytics_Portfolio from './analytics/portfolio'

// style
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap4-toggle/css/bootstrap4-toggle.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'icheck/skins/all.css'
import '../css/AdminLTE/AdminLTE.css'
import '../css/AdminLTE/skin-green-light.css'
import '../css/style.css'

document.addEventListener("DOMContentLoaded", function() {

    const JRSPayApp = {
        init() {
            window.jQuery = $;

            let adminlte = new AdminLTE();
            adminlte.init();

            const pathname = window.location.pathname;

            switch (pathname) {

                case '/offline/':
                    break;

                case '/login/':
                    Login();
                    break;

                case '/home/':
                    Home();
                    break;

                case '/profile/':
                    Profile();
                    break;

                case '/balance-sheet/':
                    BalanceSheet();
                    break;

                case '/retirement/':
                    Retirement();
                    break;

                case '/current-assets/ar/':
                    AccountsReceivable();
                    break;

                case '/current-assets/cash/':
                    AccountsCash();
                    break;

                case '/liabilities/ap/':
                    AccountsPayable();
                    break;

                case '/liabilities/notes/':
                    NotesPayable();
                    break;

                case '/expenses/bill-pay-checklist/':
                    BillPayChecklist();
                    break;

                case '/expenses/bills/':
                    BillSetup();
                    break;

                case '/expenses/itemized/':
                    ItemizedExpenses();
                    break;

                case '/expenses/work/':
                    WorkExpenses();
                    break;

                case '/expenses/tax-payments/':
                    TaxPayments();
                    break;

                case '/noncurrent-assets/real-estate/':
                    RealEstate();
                    break;

                case '/noncurrent-assets/fixed/':
                    FixedAssets();
                    break;

                case '/noncurrent-assets/investments/':
                    Investments();
                    break;

                case '/revenue/gigs/':
                    Revenue_Gigs();
                    break;

                case '/revenue/k1/':
                    Revenue_K1();
                    break;

                case '/revenue/payouts/':
                    Revenue_Payouts();
                    break;

                case '/revenue/w2/':
                    Revenue_W2()
                    break;

                case '/revenue/freelance/':
                    Revenue_Freelance();
                    break;

                case '/revenue/tax-refunds/':
                    Revenue_TaxRefunds();
                    break;

                case '/revenue/misc/':
                    Revenue_Misc();
                    break;

                case '/settings/clients/':
                    Settings_Clients();
                    break;

                case '/settings/offices/':
                    Settings_Offices();
                    break;

                case '/settings/users/':
                    Settings_Users();
                    break;

                case '/analytics/assets/':
                    Analytics_Assets();
                    break;

                case '/analytics/portfolio/':
                    Analytics_Portfolio();
                    break;

                case '/analytics/bills/':
                    Analytics_Bills();
                    break;

                case '/analytics/cash-flows/':
                    Analytics_CashFlows();
                    break;

                case '/analytics/taxes/':
                    Analytics_Taxes();
                    break;

                default:
                    throw "nav not handled " + pathname

            }
        },
    };

    JRSPayApp.init()

    const checkWorkerActive = async () => {
        // Get registration object
        const swRegistration = await navigator.serviceWorker.getRegistration();
        return !!swRegistration;
    }

    const registerWorker = async () => {
        try {
            // Define the serviceworker and an optional options object.
            const worker = navigator.serviceWorker;

            // Register the worker and return the registration object to the calling function
            return await worker.register('/serviceworker.js');
        } catch (e) {
            console.error(e);
        }
    }

    checkWorkerActive()
        .then(registered => {
            if (!registered) registerWorker()
        })

});

// When the DOM is done loading, call these functions:
window.addEventListener('load', () => {

    const checkOnline = () => {
        const isOnline = navigator.onLine;
        const indicator = $('.online-status-header');
        if (!indicator) {
            return
        }

        const is_visible = indicator.is(':visible');
        if (isOnline && is_visible) {
            indicator.hide();
        } else if (!isOnline && !is_visible) {
            indicator.show();
        }
    };

    checkOnline();
    setInterval(() => checkOnline(), 2000);
});
