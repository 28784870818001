import JRSPay from './JRSPay';
import axios from 'axios'
import $ from "jquery";
import {DateTime} from 'luxon';

export default function() {
    const $user_form = $('#frmUserSettings')
    const $household_form = $('#frmHouseholdSettings')
    const current_user_id = JRSPay.current_user_id();

    async function getUserData() {
        const response = await axios.get('/api/v1/settings/user/?user_id=' + current_user_id);
        return response.data.data;
    }

    async function getHouseholdData(household_id) {
        const response = await axios.get('/api/v1/settings/household/?household_id=' + household_id);
        return response.data.data;
    }

    function init() {
        getUserData()
            .then(user => {
                console.log(user);

                // titles
                $('.profile-username').text(user.name)
                $('.profile-usertitle').text(user.title)

                // user form
                $('[name=user_id]').val(user.id)
                $('.user-name', $user_form).val(user.name)
                $('.user-username', $user_form).val(user.username)
                $('.user-email', $user_form).val(user.email)
                $('.user-title').val(user.title)
                $('.user-birthdate', $user_form).val(user.birthdate)
                $('.user-has_gigs_enabled', $user_form).iCheck(user.has_gigs_enabled ? "check" : "uncheck");
                $('.user-household').text(user.household)

                const birthdate = DateTime.fromSQL(user.birthdate)
                const today = DateTime.fromJSDate(new Date())
                const diff = today.diff(birthdate, ["years", "months", "days", "hours"])
                $('.user-age').text(diff.values.years)

                return getHouseholdData(user.household_id)
            })
            .then(household => {
                console.log(household);

                // user data
                $('[name=household_id]').val(household.id)
                $('.hh-name', $household_form).val(household.name)
                $('.hh-retirement_age', $household_form).val(household.retirement_age)
                $('.hh-life_expectancy', $household_form).val(household.life_expectancy)
            })
    }

    $('.user-household.btn-link').click(function() {
        $('a[href="#tab-household"]').tab('show')
    })

    $user_form.submit(function(evt) {
        evt.preventDefault();

        let payload = $user_form.serializeObject();
        payload.action = "profile-update"

        axios.patch('/api/v1/settings/user/', payload)
            .then(response => {
                response = response.data;
                console.log(response);
                init();
            })
            .catch(error => {
                console.error(error.response.data.message);
                alert('Something went wrong: ' + error.response.data.message)
            })

        return false;
    });

    $household_form.submit(function(evt) {
        evt.preventDefault();

        let payload = $household_form.serializeObject();
        payload.action = "profile-update"

        axios.patch('/api/v1/settings/household/', payload)
            .then(response => {
                response = response.data;
                console.log(response);
                init();
            })
            .catch(error => {
                console.error(error.response.data.message);
                alert('Something went wrong: ' + error.response.data.message)
            })

        return false;
    });

    init()
}
